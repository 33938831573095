<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="p-0 filter-block">
          <div class=" w-100">
            <div class="filters  w-100">
              <div class="filters__search">
                <div class="input-group input-group-sm filters__input">
                  <span class="input-group-addon">
                    <i class="fa fa-spin fa-circle-o-notch" v-if="searchSpinner"></i>
                    <i class="fa fa-search" v-else></i>
                  </span>
                  <input
                    id="search"
                    name="search"
                    :placeholder="$t('general.search')"
                    @keyup.13="searchInputHandler(searchValue)"
                    class="form-control"
                    v-model="searchValue"
                    @input="searchInputHandler(searchValue)"
                  />
                  <span class="clear-input text-muted">
                    <i class="fa fa-remove" v-if="searchValue && !searchSpinner" @click="searchInputHandler('')"></i>
                  </span>
                </div>

<!--                                <div class="input-group input-group-sm filters__input ml-1">-->
<!--                  <span class="input-group-addon filters__field-icon">-->
<!--                    <i class="fa fa-map-marker" :class="{ 'text-primary': filters.location !== 'All locations' }"></i>-->
<!--                  </span>-->
<!--                  <select-->
<!--                    class="form-control form-control-sm filters__field"-->
<!--                    ref="locationsSelect"-->
<!--                    v-model="filters.location"-->
<!--                    @change="this.refreshCpesListQuery"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  >-->
<!--                    <option :selected="true" :value="'All locations'">-->
<!--                      {{ $t('general.allLocations') }}-->
<!--                    </option>-->
<!--                    <option v-for="location in locationsList" :value="location.id" :key="location.id">-->
<!--                      {{ location.name }}-->
<!--                    </option>-->
<!--                  </select>-->
<!--                </div>-->

<!--                <div-->
<!--                class=" d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper">-->
<!--                <div-->
<!--                  class="  d-flex align-items-end justify-content-start"-->
<!--                >-->
<!--                  <Switch-component-->
<!--                    :disabled="this.filters.location === 'All locations'"-->
<!--                    v-model="filters.with_childs"-->
<!--                    class="switcher-with-childs-for-use-in-filters"-->
<!--                    @change="this.refreshCpesListQuery"-->
<!--                  />-->
<!--                </div>-->
<!--                <div-->
<!--                  class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">-->
<!--                  <small class="with-child">{{ $t('general.withChild') }}</small>-->
<!--                </div>-->
<!--              </div>-->

                <div
                  class="input-group-for-location-select-in-rrm--filters   ml-1" v-if="areLocationsVisible">
                                <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors

                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-rrm--filters"
                    enable-max-option-width
                    small-size
                    :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="refreshCpesListQuery"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>
                </div>

                <div class="input-group input-group-sm filters__location ml-1">
                  <span class="input-group-addon filters__field-icon">
                    <i class="fa fa-sitemap" :class="{ 'text-primary': filters.group !== 'No RRM filter' }"></i>
                  </span>
                  <select
                    class="form-control form-control-sm filters__field"
                    v-model="filters.group"
                    @change="refreshCpesListQuery"
                    :disabled="muteCpeFilters"
                  >
                    <option :selected="true" :value="'No RRM filter'">
                      {{ $t('RRM.allGroups') }}
                    </option>
                    <option v-for="rrm in rrmList" :value="rrm.id">
                      {{ rrm.name }}
                    </option>
                  </select>
                </div>



                <div class="input-group input-group-sm filters__location ml-1">
                  <span class="input-group-addon filters__field-icon">
                    <img
                      v-if="filters.models === 'All models'"
                      class="img img-responsive cpe-icon"
                      src="/static/img/router-icon.svg"
                      alt=""
                    />
                    <img v-else class="img img-responsive cpe-icon" src="/static/img/router-icon-blue.svg" alt="" />
                  </span>
                  <select
                    class="form-control form-control-sm filters__field"
                    v-model="filters.models"
                    @change="this.refreshCpesListQuery"
                    :disabled="muteCpeFilters"
                  >
                    <option :selected="true" :value="'All models'">
                      {{ $t('general.allModels') }}
                    </option>
                    <option v-for="cpeModel in cpeModelsList" :value="cpeModel.id">
                      {{ cpeModel.description }}
                    </option>
                  </select>
                </div>

                <div class="input-group input-group-sm filters__location ml-1">
                  <span class="input-group-addon filters__field-icon">
                    <i class="fa fa-tag" :class="{ 'text-primary': filters.tag !== 'All tags' }"></i>
                  </span>
                  <select
                    class="form-control form-control-sm filters__field"
                    v-model="filters.tag"
                    @change="refreshCpesListQuery"
                    :disabled="muteCpeFilters"
                  >
                    <option :selected="true" :value="'All tags'">
                      {{ $t('general.allTags') }}
                    </option>
                    <option :selected="true" v-if="cpesTagsCurrentList && cpesTagsCurrentList.length" :value="''">
                      {{ $t('general.noTag') }}
                    </option>
                    <option v-for="tag in cpesTagsCurrentList" :value="tag">
                      {{ tag }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="filters__multiple">
                <div class="btn-group btn-group-sm float-left" data-toggle="button">
                  <label class="btn btn btn-outline-primary" :class="{ active: filters.connectedFilter === true }">
                    <input
                      type="radio"
                      name="connectedFilter"
                      @click="setFilter('connectedFilter', true)"
                      :disabled="muteCpeFilters"
                    />
                    {{ $t('aps.connected') }}
                  </label>
                  <label class="btn btn-outline-primary" :class="{ active: filters.connectedFilter === false }">
                    <input
                      type="radio"
                      name="connectedFilter"
                      @click="setFilter('connectedFilter', false)"
                      :disabled="muteCpeFilters"
                    />
                    {{ $t('aps.notConnected') }}
                  </label>
                </div>
                <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">
                  <label class="btn btn-outline-primary" :class="{ active: filters.activeFilter === true }">
                    <input
                      type="radio"
                      name="activeFilter"
                      @click="setFilter('activeFilter', true)"
                      :disabled="muteCpeFilters"
                    />
                    {{ $t('aps.active') }}
                  </label>
                  <label class="btn btn-outline-primary" :class="{ active: filters.activeFilter === false }">
                    <input
                      type="radio"
                      name="activeFilter"
                      @click="setFilter('activeFilter', false)"
                      :disabled="muteCpeFilters"
                    />
                    {{ $t('aps.notActive') }}
                  </label>
                </div>
                <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">
                  <label class="btn btn-outline-primary" :class="{ active: filters.interfacesFilter === 1 }">
                    <input
                      type="radio"
                      name="interfacesFilter"
                      @click="setFilter('interfacesFilter', 1)"
                      :disabled="muteCpeFilters"
                    />
                    {{ $t('aps.oneBand') }}
                  </label>
                  <label class="btn btn-outline-primary" :class="{ active: filters.interfacesFilter === 2 }">
                    <input
                      type="radio"
                      name="interfacesFilter"
                      @click="setFilter('interfacesFilter', 2)"
                      :disabled="muteCpeFilters"
                    />
                    {{ $t('aps.dualBand') }}
                  </label>
                </div>
              </div>
              <div v-if="isFiltersActive" class="mb-1">
                <WButton secondary outline sm
                  @click="resetAllFilters"
                >
                  <i class="fa fa-close"></i>
                  {{ $t('general.resetAllFilters') }}
                </WButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Alert class="alert-warning" closable>
      <template #text>
        <span>
          {{ $t('RRM.alertText') }}
          <a href="#" class="alert-link" @click.stop.prevent="goToAps">{{ $t('RRM.alertAction') }}</a>
        </span>
      </template>
    </Alert>

    <div class="row">
      <div v-if="isCpesLoading" class="col-lg-12 animated fadeOut">
        <div class="card">
          <!--          <div class="loader loader-backdrop loader-backdrop-rrm"></div>-->
          <div><Loader-spinner centered></Loader-spinner></div>
          <div class="card-header">
            <span>
              <i class="fa fa-align-justify"></i>
              {{ $t('aps.cpesList') }}
            </span>
            <div class="card-block"></div>
          </div>
        </div>
      </div>
      <div v-else class="col-lg-6 col-md-6 animated fadeIn">
        <div class="cpes-list sticky-top">
          <div class="card">
            <!--            <div class="loader loader-backdrop loader-backdrop-rrm" v-if="isCpesLoading"></div>-->
            <div v-if="isCpesLoading"><Loader-spinner /></div>
            <div class="card-header">
              <span>
                <i class="fa fa-align-justify"></i>
                {{ $t('aps.cpesList') }}
              </span>
              <span class="text-muted ml-3" v-if="!isCpesLoading">
                {{ $t('RRM.tableView') }} {{ cpesList.length }} {{ $t('RRM.tableItemsOf') }} {{ this.totalResult }}
                <span v-if="selectedCpesId.length">, {{ $t('RRM.tableSelected') }}: {{ selectedCpesId.length }}</span>
              </span>
            </div>

            <!-- <div class="card-block"> -->
            <table class="table">
              <thead>
                <tr>
                  <th v-if="!isOperator">
                    <Checkbox-component :checked="isAllChecked" @click="checkAllCpes" id="checkAll" />
                  </th>
                  <th>{{ $t('general.name') }}</th>
                  <th>{{ $t('RRM.group') }}</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="cpe in cpesList" class="cpe-row" :key="cpe.id" :data-id="cpe.id" @click="checkCpe">
                  <td v-if="!isOperator">
                    <Checkbox-component :id="cpe.id" :checked="selectedCpesId.includes(cpe.id)" @click="checkCpe" />
                  </td>

                  <td class="td-name">
                    <span
                      :data-model="cpe.model.name"
                      class="badge-cpe badge-status ml-h"
                      :class="{
                        'badge-status__connected': cpe.connected,
                        'badge-status__disconnected': !cpe.connected
                      }"
                    >
                      <span v-if="cpe.name">{{ cpe.name }}</span>
                      <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                    </span>
                  </td>
                  <td class="responsive-row">
                    <span :class="{ 'text-muted': !cpe.rrm_group || !cpe.rrm_group.rrm_group }">
                      <span v-if="cpe.rrm_group && cpe.rrm_group.rrm_group">
                        {{ commonService.getRrmById(cpe.rrm_group.rrm_group) }}
                      </span>
                      <span v-else>{{ $t('misc.no_data') }}</span>
                    </span>
                  </td>
                </tr>
                <tr v-if="!cpesList.length">
                  <td colspan="3" class="no-data-row">
                    <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div v-if="canLoadMore && !isCpesLoading && cpesList.length" class="card-block text-center p-0">
              <WButton link
                @click="loadMore"
              >
                {{ $t('RRM.tableLoad') }}
                <span v-if="this.limit < this.totalResult - this.cpesList.length">{{ this.limit }}</span>
                <span v-else>{{ this.totalResult - this.cpesList.length }}</span>
                {{ $t('RRM.tableOutOf') }} {{ this.totalResult - this.cpesList.length }}
              </WButton>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>

      <div v-if="!isCpesLoading" class="col-lg-6 col-md-6 animated fadeIn">
        <div class="rrm-list sticky-top">
          <div class="card group-action" v-if="selectedCpesId.length">
            <div class="card-block">
              <p class="h4 mb-1">
                {{ $t('RRM.multipleEdit') }}
              </p>

              <div class="form-group mb-2">
                <select
                  v-model="currentGroupForMove"
                  name="rrm_groups"
                  id="rrm_groups"
                  :class="{ 'select-disabled': isDisable }"
                  class="form-control mb-q"
                  :disabled="isDisable"
                >
                  <option value="" disabled selected>
                    {{ $t('RRM.multipleEditSelectGroup') }}
                  </option>
                  <option v-for="group in rrmList" :key="group.id" :value="group.id">
                    {{ group.name }}
                  </option>
                </select>
                <WButton success fullWidth
                  @click="moveToGroup"
                  :disabled="!currentGroupForMove || isDisable"
                >
                  {{ $t('RRM.Movetoselectedgroup') }}
                </WButton>
              </div>
              <WButton danger outline fullWidth
                customClass="p-h mb-2"
                @click="removeFromGroups"
                :disabled="!isCpesHaveRrm || isDisable"
              >
                {{ $t('RRM.RemovefromRRMgroups') }}
              </WButton>
              <WButton secondary outline fullWidth
                customClass="btn-md p-h"
                @click="resetAllCheckedCpes"
              >
                {{ $t('general.close') }}
              </WButton>
            </div>
          </div>

          <div class="card">
            <!--            <div class="loader loader-backdrop loader-backdrop-rrm" v-if="isRRMLoading"></div>-->
            <div v-if="isRRMLoading"><Loader-spinner /></div>
            <div class="card-header d-flex justify-content-between">
              <span>
                <i class="fa fa-align-justify"></i>
                RRM {{ $t('RRM.groups') }}
              </span>
              <span>
                <WButton info sm
                  v-if="!isDisable"
                  @click="openNewGroupModal"
                >
                  {{ $t('general.add') }}
                </WButton>
              </span>
            </div>

            <!-- <div class="card-block"> -->
            <table class="table">
              <thead>
                <tr>
                  <th>{{ $t('general.name') }}</th>
<!--                  <th>{{ $t('RRM.algo') }}</th>-->
                  <th>{{ $t('RRM.timeout') }}</th>
                  <th>{{ $t('RRM.powerForTable') }}</th>
                  <th>{{ $t('RRM.tableRRMCPEs') }}</th>
                  <th v-if="!isOperator"></th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="rrm in rrmList"
                  class="rrm-row"
                  :data-id="rrm.id"
                  @click="toggleEdit(rrm.id)"
                  :class="{ selected: filters.group === rrm.id }"
                >
                  <td class="td-name" :class="{ 'text-muted': !rrm.name }">
                    {{ rrm.name || 'No name' }}
                  </td>
                  <!--                  <td class="responsive-row">{{ rrm.algo.type }} ( {{ rrm.algo.data.timeout }} {{$t('RRM.tableRRMSeconds')}}. )</td>-->
<!--                  <td class="responsive-row">-->
<!--                    {{ $t(`RRM.algorithm${rrm.algo.type}`) }} ( {{ rrm.algo.data.timeout }}-->
<!--                    {{ $t('RRM.tableRRMSeconds') }}. )-->
<!--                  </td>-->
                  <td class="responsive-row">
                    {{ rrm.algo.data.timeout }}
                    {{ $t('RRM.tableRRMSeconds') }}.
                  </td>
                  <td>
                    <span
                      :class="{
                        'badge-success': rrm.algo.data.manage_power,
                        'badge-default': !rrm.algo.data.manage_power
                      }"
                      class="badge badge-light mr-q"
                    >
                      <i class="fa fa-signal"></i>
                    </span>
                  </td>
                  <!--<td>-->
                  <!--<span-->
                  <!--:class="{'badge-success':rrm.cpes.length, 'badge-default': !rrm.cpes.length}"-->
                  <!--class="badge badge-light mr-q"-->
                  <!--&gt;-->
                  <!--<img class="img img-responsive cpe-badge" src="/static/img/router-icon-white.svg" alt="">-->
                  <!--{{ rrm.cpes.length || '0'}}-->
                  <!--</span>-->
                  <!--</td>-->
                  <td>
                    <span
                      v-tooltip.top-center="{ content: `${$t('RRM.tableRRMCPEsTooltip')}` }"
                      class="tooltip-text"
                      @click.stop="setFilter('group', rrm.id)"
                    >
                      {{ rrm.cpes.length }}
                    </span>
                  </td>
                  <td v-if="!isOperator"><span @click.stop.prevent="clickRRMForceButtonInTableHandler(rrm)" v-tooltip.top-center="{ content: `${$t('RRM.rrmForceCallButtonInModal')}` }">
                    <i role="button" class="fa fa-play-circle" aria-hidden="true"></i></span></td>
                </tr>
                <tr v-if="!rrmList.length">
                  <td colspan="4" class="no-data-row">
                    <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>

    <modal
      showModalUnderVueNotifications
      title="RRM group settings"
      @cancel="cancelEditMode"
      v-model="groupSettingsModal"
      class="modal-success"
      v-if="updatedGroup"
      closeBtn
    >
      <h4 slot="modal-title" class="modal-title" v-if="!isNewGroup && updatedGroup">
        {{ $t('general.Edit') }} {{ updatedGroup.name || 'No name' }}
      </h4>
      <h4 slot="modal-title" class="modal-title" v-else>{{ $t('RRM.modalTitle') }}</h4>

      <div v-if="updatedGroup">
        <div class="form-group">
          <label for="name">{{ $t('aps.name') }}</label>
          <input
            type="text"
            id="name"
            :placeholder="$t('RRM.modalGroupNamePlaceHolder')"
            name="name"
            :disabled="isDisable"
            v-validate="'required|max:15'"
            class="form-control"
            :class="{ input: true, 'is-danger': errors.has('name') }"
            v-model="updatedGroup.name"
          />
          <span v-show="errors.has('description')" class="help is-danger">{{ errors.first('name') }}</span>
        </div>

<!--        <div class="form-group">-->
<!--          <label for="algo">{{ $t('RRM.algo') }}</label>-->
<!--          <select-->
<!--            v-model="updatedGroup.algo.type"-->
<!--            name="algo"-->
<!--            id="algo"-->
<!--            :class="{ 'select-disabled': isDisable }"-->
<!--            class="form-control"-->
<!--            :disabled="isDisable"-->
<!--          >-->
<!--            <option v-for="algo in this.RRM_ALGOS" :key="algo" :value="algo">-->
<!--              &lt;!&ndash;              {{ algo }}&ndash;&gt;-->
<!--              {{ $t(`RRM.algorithm${algo}`) }}-->
<!--            </option>-->
<!--          </select>-->
<!--        </div>-->

        <div class="form-group">
          <label for="name">{{ $t('RRM.timeout') }}</label>
          <input
            type="text"
            id="timeout"
            :placeholder="$t('RRM.modalGroupTimeoutPlaceHolder')"
            name="timeout"
            v-validate="'required|between:300,86400'"
            class="form-control"
            :disabled="isDisable"
            :class="{ input: true, 'is-danger': errors.has('timeout') }"
            v-model.number="updatedGroup.algo.data.timeout"
          />
          <span v-show="errors.has('timeout')" class="help is-danger">{{ errors.first('timeout') }}</span>
        </div>

        <div class="form-group mt-2 mb-1">
          <Switch-component
            v-model="updatedGroup.algo.data.manage_power"
            :disabled="isDisable"
            :label="$t('RRM.power')"
            :id="'manage-power'"
          />
        </div>

        <div class="form-group" v-if="areLocationsVisible">
          <label for="location">{{ $t('aps.location') }}</label>
          <select
            v-model="updatedGroup.base_location"
            name="location"
            id="location"
            :class="{ 'select-disabled': isDisable }"
            class="form-control"
            :disabled="isDisable"
          >
            <option v-for="location in locationsList" :key="location.id" :value="location.id">
              {{ location.name }}
            </option>
          </select>
        </div>
        <p v-if="!isNewGroup">
          <strong>{{ $t('RRM.modalCPEs') }}:</strong>
          {{ updatedGroup.cpes.length || `${$t('RRM.modalNOCPEs')}` }}
          <a href="#" v-if="updatedGroup.cpes.length" @click.prevent="setFilter('group', updatedGroup.id)">{{$t('RRM.showAll')}}</a>
        </p>
      </div>

      <div slot="modal-footer" class="modal-footer justify-content-between">
        <div>
        <WButton success outline
          v-if="!isNewGroup && !isDisable"
          @click="updateGroup"
          :disabled="muteEdit || errors.any()"
        >
          <span :class="{ invisible: muteEdit }">{{ $t('general.save') }}</span>
          <span v-if="muteEdit" class="loader loader--mini"></span>
        </WButton>
        <WButton success outline
          v-else-if="!isDisable"
          @click="createGroup"
          :disabled="muteEdit || errors.any()"
        >
          <span :class="{ invisible: muteEdit }">{{ $t('general.create') }}</span>
          <span v-if="muteEdit" class="loader loader--mini"></span>
        </WButton>
        <WButton success outline
          customClass="ml-2"
          v-if="!isDisable && !isNewGroup && forceRRMGroup"
          @click="forceRRMCallFromEditModal"
          :disabled="updatedGroupIsChanged"
        >
          <span v-tooltip.top-center="{ content: updatedGroupIsChanged ? `${$t('RRM.rrmForceCallButtonInModalDisabledHint')}` : '' }" :class="{ invisible: forceRRMGroupRequestExecuting }">{{ $t('RRM.rrmForceCallButtonInModal') }}</span>
          <span v-if="forceRRMGroupRequestExecuting" class="loader loader--mini"></span>
        </WButton>
      </div>
      <div>
        <WButton danger outline
          customClass="mr-2"
          v-if="!isNewGroup && !isDisable"
          @click="deletingGroupModal = true"
        >
          {{ $t('general.delete') }}
        </WButton>
        <WButton secondary outline
          @click="cancelEditMode"
        >
          {{ $t('general.close') }}
        </WButton>
      </div>
    </div>

      <modal title="Delete RRM group" v-model="deletingGroupModal" class="modal-danger" effect="fade/zoom">
        <h4 slot="modal-title" class="modal-title">{{$t('RRM.deleteRRMHeader')}}</h4>
        {{$t('general.confirmDeleting')}}
        <b>{{ this.updatedGroup.name }}</b>
        ?
        <div slot="modal-footer" class="modal-footer">
          <WButton secondary
            @click="deletingGroupModal = false"
          >
            {{ $t('general.cancel') }}
          </WButton>
          <WButton danger outline
            @click="deleteGroup"
          >
            {{ $t('general.yesDelete') }}
          </WButton>
        </div>
      </modal>
    </modal>
    <modal showModalUnderVueNotifications v-if="rrmForceCallModal && forceRRMGroup" title="Force call RRM group"
           v-model="rrmForceCallModal"
           class="modal-success" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{$t('RRM.rrmForceCallButtonInModal')}}</h4>
      <div>{{$t('RRM.forGroup')}}:&nbsp; <strong>{{forceRRMGroup.name}}</strong></div>
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="closeRRMForceCallModal"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton success outline
          customClass="ml-2"
          v-if="!isDisable && forceRRMGroup"
          @click="forceRRMCallFromRRMForceCallModal"
        >
          <span
              v-tooltip.top-center="{ content: updatedGroupIsChanged ? `${$t('RRM.rrmForceCallButtonInModalDisabledHint')}` : '' }"
              :class="{ invisible: forceRRMGroupRequestExecuting }">{{ $t('RRM.rrmForceCallButtonInModal') }}</span>
            <span v-if="forceRRMGroupRequestExecuting" class="loader loader--mini"></span>
        </WButton>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import Modal from '../components/Modal.vue';
import Alert from '../components/Universal/alert/alert.vue';
import CheckboxComponent from '../components/Universal/Checkbox-component.vue';
import SwitchComponent from '../components/Universal/Switch-component.vue';
import rrmService from '../services/rrmService';
import cpeService from '../services/cpeService';
import templatesService from '../services/templatesService';
import commonService from '../services/commonService';
import helpers from '../helpers';
import locationService from "../services/locationService";
import SelectComponent from '../components/Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from '../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';

const watcher = new helpers.ChangesWatcher();


export default {
  name: 'RRM',
  components: {
    Modal,
    CheckboxComponent,
    SwitchComponent,
    Alert,
    SelectComponent,
    SelectComponentV3ForUseInLocationSelectors
  },

  data() {
    return {
      showLocationFilter: true,
      locationInFiltersData: [],
      limit: 50,
      offset: 0,
      updatedGroup: false,
      updatedGroupIsChanged: false,
      forceRRMGroup: undefined,
      rrmForceCallModal: false,
      deletingGroupModal: false,
      totalResult: false,
      searchSpinner: '',
      searchValue: '',
      currentGroupForMove: '',
      isNewGroup: false,
      filters: {
        query: '',
        interfacesFilter: null,
        connectedFilter: null,
        activeFilter: null,
        radarFilter: null,
        scanningFilter: true,
        location: 'All locations',
        models: 'All models',
        tag: 'All tags',
        group: 'No RRM filter',
        with_childs: false,
      },
      // RRM_ALGOS: ['Greed', 'Dummy', 'Blind'],
      RRM_ALGOS: ['Greed', 'Blind'],
      selectedCpesId: [],
      allChecked: false,
      isCpesHaveRrm: false,
      canLoadMore: false,
      groupSettingsModal: false
    };
  },
  watch: {
    selectedCpes() {
      if (this.cpesList) {
        for (const cpe of this.cpesList) {
          if (this.selectedCpesId.includes(cpe.id) && cpe.rrm_group.rrm_group) {
            this.isCpesHaveRrm = true;
            break;
          } else {
            this.isCpesHaveRrm = false;
          }
        }
      }
    },
    updatedGroup: {
      deep: true,
      handler() {
        watcher.tick();
        this.$nextTick(()=>{
          if (watcher.isChanged){
            this.updatedGroupIsChanged = true
          }
        })
      }
    }
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    selectedCpes() {
      return this.selectedCpesId;
    },
    isOperator() {
      return this.$store.state.userData.role == 'operator';
    },
    isDisable() {
      return this.$store.state.userData.role == 'operator';
    },
    muteEdit() {
      return this.$store.state.muteRRMEdit;
    },
    forceRRMGroupRequestExecuting() {
      return this.$store.state.forceRRMGroupRequestExecuting;
    },
    commonService(id) {
      return commonService;
    },
    isAllChecked() {
      let result = true;
      let cpesIdsList;
      if (this.cpesList) {
        cpesIdsList = helpers.combineIdsArrayFromObjectsArray(this.cpesList);
      }
      if (cpesIdsList && cpesIdsList.length && this.selectedCpesId && this.selectedCpesId.length) {
        cpesIdsList.forEach((cpe_id) => {
          if (!this.selectedCpesId.includes(cpe_id)) {
            return (result = false);
          }
        });
      } else {
        return (result = false);
      }
      return result;
    },
    isFiltersActive() {
      return (
        this.filters.interfacesFilter ||
        typeof this.filters.activeFilter === 'boolean' ||
        typeof this.filters.connectedFilter === 'boolean' ||
        this.filters.tag !== 'All tags' ||
        this.filters.group !== 'No RRM filter' ||
        this.filters.location !== 'All locations' ||
        this.filters.models !== 'All models' ||
        this.searchValue
      );
    },
    muteCpeFilters() {
      return this.$store.state.muteCpeFilters;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    cpesList() {
      return this.$store.state.cpesList;
    },
    rrmList() {
      return this.$store.state.rrmList;
    },
    cpeModelsList() {
      return this.$store.state.cpeModelsList;
    },
    cpesTagsCurrentList() {
      return this.$store.state.cpesTagsList;
    },
    isCpesLoading() {
      return this.$store.state.loadingCpes;
    },
    isRRMLoading() {
      return this.$store.state.loadingRRM;
    }
  },
  methods: {
    goToAps(){
      this.$router.push({ name: 'Aps'});
    },
    toggleEdit(id) {

      watcher.reset();
      this.updatedGroupIsChanged = false;
      this.isNewGroup = false;
      if (!this.rrmList) {
        return;
      }

      for (const group of this.rrmList) {
        if (group.id === id) {
          this.updatedGroup = JSON.parse(JSON.stringify(group));
          this.groupSettingsModal = true;
          this.forceRRMGroup = JSON.parse(JSON.stringify(this.updatedGroup))

          // так как оставляемт по умолчанию только Greed и вообще выпилываем это из интерфейса
          // телаем его принудительную установку при редактировании RRM группы.
          if (this.updatedGroup.algo.type !== 'Greed'){
            this.updatedGroup.algo.type = 'Greed'
          }
        }
      }
    },
    clickRRMForceButtonInTableHandler(rrmObject) {
      this.forceRRMGroup = JSON.parse(JSON.stringify(rrmObject));
      this.openRRMForceCallModal();

    },
    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.refreshCpesListQuery();

    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      //  return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    openRRMForceCallModal() {
      this.rrmForceCallModal = true;
    },
    closeRRMForceCallModal() {
      this.rrmForceCallModal = false;
      this.forceRRMGroup = false;
    },
    async forceRRMCallFromRRMForceCallModal() {
      if (this.forceRRMGroupRequestExecuting) {
        return;
      }
      const rrmGroupObject = JSON.parse(JSON.stringify(this.forceRRMGroup));
      try {
        await rrmService.forceRRMGroup(this, rrmGroupObject, () => {
          this.closeRRMForceCallModal();
        });
      } catch (e) {
        console.log(e);
      }
    },
    forceRRMCallFromEditModal() {
      if (this.forceRRMGroupRequestExecuting) {
        return;
      }
      const rrmGroupObject = JSON.parse(JSON.stringify(this.forceRRMGroup));
      rrmService.forceRRMGroup(this, rrmGroupObject);
    },
    openNewGroupModal() {
      this.isNewGroup = true;
      this.updatedGroup = {
        name: '',
        algo: {
          data: {
            timeout: 1800,
            manage_power: false
          },
          type: 'Greed'
        },
        base_location: this.$store.state.userData.base_location,
        cpes: []
      };
      this.groupSettingsModal = true;
    },
    loadMore() {
      this.offset++;
      cpeService.getPaginatedCpes(this, this.limit, this.offset);
    },

    checkCpe(e) {
      this.cancelEditMode();
      const selectedNode = e.target.closest('[data-id]');
      const { id } = selectedNode.dataset;

      if (this.selectedCpesId.includes(id)) {
        this.selectedCpesId.splice(this.selectedCpesId.indexOf(id), 1);
        if (this.selectedCpesId.length === 0) {
        }
      } else {
        this.selectedCpesId.push(id);
      }
    },
    checkAllCpes() {
      const filtredSelectedId = document.querySelectorAll('.cpe-row');
      if (this.cpesList.length) {
        if (!this.isAllChecked) {
          filtredSelectedId.forEach((cpe) => {
            if (!this.selectedCpesId.includes(cpe.dataset.id)) {
              this.selectedCpesId.push(cpe.dataset.id);
            }
          });
        } else if (this.isAllChecked) {
          this.selectedCpesId = [];
          this.cancelEditMode();
        }
      }
    },
    resetAllCheckedCpes() {
      this.selectedCpesId = [];
    },
    searchInputHandler(value) {
      this.cancelEditMode;
      this.searchValue = value;
      this.searchSpinner = true;
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        this.refreshCpesListQuery();
      }, 1000);
    },
    refreshCpesListQuery() {
      rrmService.getRRMsInRRMPage(this);
      this.selectedCpesId = [];
      this.allChecked = false;

      this.offset = 0;
      this.canLoadMore = true;
      this.refreshCpes();
    },
    refreshCpes() {
      cpeService.refreshCpes(this, 'spinner');
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    // resetAllFilters() {
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.filters = {
    //     query: '',
    //     interfacesFilter: null,
    //     connectedFilter: null,
    //     activeFilter: null,
    //     scanningFilter: null,
    //     location: 'All locations',
    //     models: 'All models',
    //     tag: 'All tags',
    //     group: 'No RRM filter',
    //     with_childs: false,
    //   };
    //   this.searchValue = '';
    //   this.refreshCpes();
    // },
    resetAllFilters() {
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      this.filters = {
        query: '',
        interfacesFilter: null,
        connectedFilter: null,
        activeFilter: null,
        scanningFilter: true,
        radarFilter: null,
        // location: 'All locations',
        models: 'All models',
        tag: 'All tags',
        group: 'No RRM filter',
        // with_childs: false,
      };
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this);
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();

      this.searchValue = '';
      this.refreshCpes();
    },
    setFilter(filterName, value) {
      if (this.muteCpeFilters) {
        return;
      }
      this.selectedCpesId = [];
      this.allChecked = false;
      const checkAllElement = document.getElementById('checkAll');
      if (checkAllElement) {
        checkAllElement.checked = false;
      }

      this.cancelEditMode();
      if (this.filters[filterName] !== value) {
        this.filters[filterName] = value;
      } else if (
        filterName === 'activeFilter' ||
        filterName === 'connectedFilter' ||
        filterName === 'interfacesFilter'
      ) {
        this.filters[filterName] = null;
      }

      this.refreshCpesListQuery();
    },
    cancelEditMode() {
      watcher.reset();
      this.updatedGroupIsChanged = false;
      this.forceRRMGroup = undefined;
      this.groupSettingsModal = false;
      this.deletingGroupModal = false;
      this.currentGroupForMove = '';
      Vue.nextTick(() => {
        this.updatedGroup = false;
      });
      document.body.classList.remove('modal-open');
    },
    updateGroup() {
      rrmService.updateRRM(this);
    },
    createGroup() {
      rrmService.createRRM(this);
    },
    deleteGroup() {
      rrmService.deleteRRM(this);
    },
    moveToGroup() {
      for (const rrm of this.rrmList) {
        if (rrm.id === this.currentGroupForMove) {
          this.updatedGroup = rrm;
        }
      }

      this.selectedCpesId.forEach((id) => {
        if (!this.updatedGroup.cpes.includes(id)) {
          this.updatedGroup.cpes.push(id);
        }
      });

      rrmService.updateRRM(this);
    },
    removeFromGroups() {
      const cpesItems = helpers.transformArrayToObject(this.cpesList);
      const rrmItems = helpers.transformArrayToObject(this.rrmList);
      const newRrmList = [];
      this.selectedCpesId.forEach((cpeId) => {
        if (!cpesItems[cpeId].rrm_group.rrm_group) {
          return;
        }
        let foundGroup = null;
        foundGroup = rrmItems[cpesItems[cpeId].rrm_group.rrm_group];
        foundGroup.id = cpesItems[cpeId].rrm_group.rrm_group;

        if (foundGroup) {
          foundGroup.cpes.forEach((cpe, index) => {
            if (cpe === cpeId) {
              foundGroup.cpes.splice(index, 1);
            }
            newRrmList.push(foundGroup);
          });
        }
      });

      rrmService.updateRRMs(this, helpers.transformArrayToObject(newRrmList));
    }
  },
  // created() {
  //   this.$store.commit('setCpesList', false);
  //   cpeService.getPaginatedCpes(this, this.limit, this.offset);
  //   rrmService.getRRMs(this);
  //   templatesService.getCpeModels(this);
  //   cpeService.getCpesTags(this);
  // }
  created() {
    this.$store.commit('setCpesList', false);

    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }
      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }


      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
    }

    cpeService.getPaginatedCpes(this, this.limit, this.offset);
    rrmService.getRRMsInRRMPage(this);
    templatesService.getCpeModels(this);
    cpeService.getCpesTags(this);
  }
};
</script>

<style lang="scss" scoped>
.cpes-list {
  display: block;
  width: auto;
  min-width: 50%;
}

.rrm-list {
  min-width: 50%;

  & tr {
    transition: background 0.15s;
  }

  & tbody .selected {
    background: #d9e3ec;
  }

  & tbody tr:hover {
    cursor: pointer;
    background: rgba(236, 236, 236, 0.59);
  }
}
</style>

<style lang="scss">
    .input-group-for-location-select-in-rrm--filters {
    // min-height: 27px !important;
    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;

  }
      .select-location-in-rrm--filters .select__list-container {
    width: 400px;
  }
      /*.select-location-in-rrm--filters .select__tag > span{*/
      /*    white-space: nowrap;*/
      /*}*/

.loader-backdrop-rrm:after {
  position: absolute;
  top: 30px;
}
.edit-btn-close {
  line-height: 14px;
  font-size: 14px !important;
  height: 27.5px;
  width: 29px;
}
</style>
