<template>
  <div class="row h-100">
    <div class="col-lg-12 h-100 position-relative">
      <loader-spinner
        class="card h-100"
        centered
        v-if="iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess"
      ></loader-spinner>
      <div v-else class=" h-100">
        <div class="card-block">
<!--          <div class="h4 m-0">-->
<!--            {{ $t('general.step9') }}-->
<!--            <button-->
<!--              type="button"-->
<!--              class="btn btn-sm float-right btn-outline-info skip-button-in-wlan-wizard new-moder-style-btn-other-btn"-->
<!--              @click="lastStepButtonClickHandler"-->
<!--            >-->
<!--              {{ $t('wlans.wizardSkipButton') }}-->
<!--              <info :content="$t('wlans.wizardSkipButtonInfo')" />-->
<!--            </button>-->
<!--          </div>-->
          <span class="text-muted step-caption-in-wlan-wizard">{{ $t('wlans.wizardWMM') }}</span>
          <span>
            <info
              @clicked="showWMMInfoDetails"
              class="info-tooltip-in-wlans-wmm"
              :class="{ 'not-for-cisco': isCiscoModeEnabled }"
              :content="$t('wmm.infoTooltip')"
            />
          </span>
          <div v-if="isCiscoModeEnabled">
            <small class="text-danger p-0" v-if="isCiscoModeEnabled">* {{ $t('wlans.notAvailableForCisco') }}</small>
          </div>
          <div class="mt-1">
            <WMM
              :showCategoryStausBadges="false"
              :updatedWlan="newWlanData"
              :isDisable="false"
              :wlanEditCurrent="wlanEditCurrent"
              :isBackgroundShow="isBackgroundShow"
              :isBestEffortShow="isBestEffortShow"
              :isVideoShow="isVideoShow"
              :isVoiceShow="isVoiceShow"
            ></WMM>
          </div>
          <form action="">
            <!--            <button-->
            <!--              type="button"-->
            <!--              class="btn btn-outline-success float-right"-->
            <!--              @click="setNewWlanData"-->
            <!--            >-->
            <!--              {{ $t('general.next') }}-->
            <!--            </button>-->
            <!--            <button type="button" class="btn btn-outline-secondary float-left"-->
            <!--                    @click="this.$parent.prevStep">-->
            <!--              {{ $t('general.back') }}-->
            <!--            </button>-->
          </form>
        </div>
<!--        <div class="buttons-block-warpper">-->
<!--          <button type="button" class="btn btn-outline-success float-right" @click="setNewWlanData">-->
<!--            {{ $t('general.next') }}-->
<!--          </button>-->
<!--          &lt;!&ndash;          <button type="button" class="btn btn-outline-info float-right mr-h"&ndash;&gt;-->
<!--          &lt;!&ndash;                  @click="lastStepButtonClickHandler">&ndash;&gt;-->
<!--          &lt;!&ndash;            {{ $t('wlans.wizardSkipButton') }}&ndash;&gt;-->
<!--          &lt;!&ndash;            <info :content="$t('wlans.wizardSkipButtonInfo')"/>&ndash;&gt;-->
<!--          &lt;!&ndash;          </button>&ndash;&gt;-->
<!--          <button type="button" class="btn btn-outline-secondary float-left" @click="this.$parent.prevStep">-->
<!--            {{ $t('general.back') }}-->
<!--          </button>-->
<!--        </div>-->
      </div>
    </div>
    <Modal title="Show WMM Info" class="modal-info" v-model="showingWMMInfoDetailsModal" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('wmm.modalInfoTitle') }}</h4>
      <WMMInfo></WMMInfo>
      <div slot="modal-footer" class="modal-footer modal-btn-right">
        <WButton secondary
          @click="showingWMMInfoDetailsModal = false"
        >
          {{ $t('general.close') }}
        </WButton>
      </div>
    </Modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import WMM from '../../../../components/WLANs/WMM.vue';
import WMMInfo from '../../../../components/WLANs/WMMInfo.vue';
import Info from '../../../../components/Universal/info-icon.vue';
import Modal from '../../../../components/Modal.vue';

const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');

export default {
  name: 'WMMTab',
  components: {
    WMM,
    WMMInfo,
    Info,
    Modal
  },
  data() {
    return {
      // enableSaveChanges: true,
      showingWMMInfoDetailsModal: false,
      wlanEditCurrent: {
        wmm: {
          categories: {}
        }
      },
      isBackgroundShow: false,
      isBestEffortShow: false,
      isVideoShow: false,
      isVoiceShow: false,
      newWlanData: {
        security: {
          data: {
            suites: [],
            psk: ''
          }
        }
      }
      // showWlanPass: false,
      // updatedWlanRadiusAuthentication: [],
      // updatedWlanRadiusAccounting: []
    };
  },
  computed: {
    ...featureFlagsMapGetters(['isCiscoModeEnabled']),
    iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess() {
      return this.$store.state.wlanWizardGoingToLastStepAfterClickSkipButtonInProgess;
    },
    window() {
      return window;
    }
  },
  methods: {
    lastStepButtonClickHandler(event) {
      this.setNewWlanData(event, true);
    },
    showWMMInfoDetails() {
      // console.log('clickeed');
      this.showingWMMInfoDetailsModal = true;
    },
    // setNewWlanData(event, toLastStepClicked = false) {
    //   if (this.newWlanData.wmm.categories) {
    //     for (const category in this.newWlanData.wmm.categories) {
    //       for (const config in this.newWlanData.wmm.categories[category]) {
    //         if (!this.newWlanData.wmm.categories[category][config]) {
    //           delete this.newWlanData.wmm.categories[category][config];
    //         }
    //       }
    //     }
    //   }
    //   this.$store.commit('setNewWlanData', this.newWlanData);
    //   this.$validator.validateAll().then(
    //     (result) => {
    //       if (result) {
    //         // this.$parent.nextStep();
    //         if (toLastStepClicked === false) {
    //           this.$parent.nextStep();
    //         } else {
    //           // this.$parent.nextStep(true);
    //           this.$parent.lastStep();
    //         }
    //       }
    //     },
    //     () => {
    //       console.warn('Validation failed');
    //     }
    //   );
    // }

    validateAndSetNewWlanData(currentTab, nextTab){
      this.setNewWlanData(currentTab, nextTab)
    },

    setNewWlanData(currentTab, nextTab) {
      if (this.newWlanData.wmm.categories) {
        for (const category in this.newWlanData.wmm.categories) {
          for (const config in this.newWlanData.wmm.categories[category]) {
            if (!this.newWlanData.wmm.categories[category][config]) {
              delete this.newWlanData.wmm.categories[category][config];
            }
          }
        }
      }
      this.$store.commit('setNewWlanData', this.newWlanData);
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            // this.$parent.nextStep();
            this.$emit('dataIsValid', { nextTab: nextTab, currentTab:  currentTab})
          }else{
            this.$emit('dataNoValid', { nextTab: nextTab, currentTab:  currentTab })
          }
        },
        () => {
          this.$emit('dataNoValid', { nextTab: nextTab, currentTab:  currentTab })
          console.warn('Validation failed');
        }
      );
    }
  },
  created() {
    // radiusService.getRadius(this);
    // this.radiusList = this.$store.state.radiusList;
    this.newWlanData = JSON.parse(JSON.stringify(this.$store.state.newWlanData));

    // проверяем не пришли ли мы сюда после нажатия кнопки Пропустить
    if (
      Object.prototype.hasOwnProperty.call(this.$route, 'params') &&
      Object.prototype.hasOwnProperty.call(this.$route.params, 'lastStepClicked') &&
      this.$route.params.lastStepClicked === true
    ) {
      this.lastStepButtonClickHandler();
    }
  }
};
</script>
<style lang="scss">
.input.is-danger .multiselect__tags {
  border-color: #ff3860;
}

.input-group {
  z-index: 1;
}
</style>
<style scoped>
.buttons-block-warpper {
  position: absolute;
  bottom: 1.25rem;
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.skip-button-in-wlan-wizard {
  font-size: 0.75rem;
}
</style>
