<template>
  <HeaderWrapper>
    <template v-slot:logo>
      <HeaderLogo/>
    </template>
    <template v-slot:left-content>
      <ul class="header-list">
        <li>
          <SidebarMenuSwitcher/>
        </li>
        <li v-if="false">
          <!-- пока скрыто до получения новых требований по правкам -->
          <SoftwareCredentials/>
        </li>
        <li v-if="!inHeaderLocationSelectModeEnabled && systemInfo && systemInfo.uptime">
          <UptimeIndicator :uptime="systemInfo.uptime"/>
        </li>
        <li v-if="inHeaderLocationSelectModeEnabled && !areLocationsHidden">
          <LocationSelector/>
        </li>
        <li>
          <ThemeSwitcher/>
        </li>
        <li v-if="isExperimentalModeEnabled">
          <MenuTypeToggler/>
        </li>
      </ul>
    </template>
    <template v-slot:right-content>
      <ul class="header-list">
        <li>
          <StartPageButton/>
        </li>
        <li v-if="isAdminRole">
          <GoToWifiWizardButton/>
        </li>
        <li>
          <LocaleSwitcher viewType="light"/>
        </li>
        <li>
          <SetUpdateIntervalDropdown/>
        </li>
        <li v-if="isExperimentalModeEnabled || isWLCProduct">
          <SearchBar/>
        </li>
        <li v-if="!isOperatiorRole">
          <CurrentOperationsDropdown/>
        </li>
        <li>
          <FullscreenModeSwitcher/>
        </li>
        <li>
          <UserSettings/>
        </li>
        <li v-if="isSubordinateLogin">
          <ContinueAsSubbordinate/>
        </li>
      </ul>
    </template>
  </HeaderWrapper>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import {
  HeaderWrapper,
  HeaderLogo,
  SidebarMenuSwitcher,
  SoftwareCredentials,
  StartPageButton,
  UptimeIndicator,
  LocationSelector,
  SearchBar,
  ThemeSwitcher,
  GoToWifiWizardButton,
  SetUpdateIntervalDropdown,
  CurrentOperationsDropdown,
  UserSettings,
  MenuTypeToggler,
  ContinueAsSubbordinate,
  FullscreenModeSwitcher
} from './components';

import LocaleSwitcher from '../LocaleSwitcher.vue';

import { oldHeaderLegacyCodeMixin } from '@/mixins';

const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');
const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');

export default {
  name: 'WHeader',
  // todo миксин с легаси. Отрефакторить и удалить его
  mixins: [oldHeaderLegacyCodeMixin],
  components: {
    HeaderWrapper,
    HeaderLogo,
    SidebarMenuSwitcher,
    SoftwareCredentials,
    StartPageButton,
    UptimeIndicator,
    LocationSelector,
    SearchBar,
    ThemeSwitcher,
    LocaleSwitcher,
    GoToWifiWizardButton,
    SetUpdateIntervalDropdown,
    CurrentOperationsDropdown,
    UserSettings,
    MenuTypeToggler,
    ContinueAsSubbordinate,
    FullscreenModeSwitcher
  },
  props: {
  },
  data() {
    return {
    };
  },
  filters: {
  },
  computed: {
    ...featureFlagsMapGetters(['isPortalOnlyModeEnabled', 'isExperimentalModeEnabled', 'areLocationsHidden']),
    ...WLCProductMapGetters(['isWLCProduct']),
    systemInfo() {
      // todo refactor
      return this.$store.state.systemTotalObjList.system;
    },
    inHeaderLocationSelectModeEnabled() {
      // todo refactor
      return this.$store.state.inHeaderLocationSelectModeEnabled;
    },
    isAdminRole() {
      // todo refactor
      return this.$store.state.userData.role === 'admin';
    },
    isOperatiorRole() {
      // todo refactor
      return this.$store.state.userData.role === 'operator';
    },
    isSubordinateLogin() {
      // todo refactor
      return this.$store.state.subordinateLogin;
    }
  },
  methods: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
.header-list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  display: flex;
  align-items: center;
  gap: 10px;
}

</style>
