import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import redirectPollingService from './redirectPollingService';
import { API_URL } from '@/config';

export default {
  // getRedirectsRule(ctx) {
  //   ctx.$store.commit('toggleLoadingRedirects');
  //   Vue.axios.post(`${API_URL || ''}/api/network/redirects`, { action: 'R' }).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         ctx.$store.commit('setRedirectsList', response.data.data.itemslist);
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //       // ctx.clearFields();
  //       ctx.$store.commit('toggleLoadingRedirects');
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('toggleLoadingRedirects');
  //       ctx.error = err;
  //     }
  //   );
  // },

  getRedirectsRule(ctx, useLocationFilter= false) {
    ctx.$store.commit('toggleLoadingRedirects');

    const isUseLocationFilters = useLocationFilter;

    const params = {
      action: 'R'
    }
    if (!isUseLocationFilters) {
      params.location =  ctx.$store.getters.getDefaultLocationForRequests(ctx);
      params.with_childs =  ctx.$store.getters.getDefaultWithChildsForRequests(ctx);

    }
    if (ctx.filters?.location && isUseLocationFilters) {
      if (ctx.filters.location === 'All locations') {
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        params.location = ctx.filters.location;
        params.with_childs = ctx.filters.with_childs;
      }
    }

    Vue.axios.post(`${API_URL || ''}/api/network/redirects`, params).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setRedirectsList', response.data.data.itemslist);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        // ctx.clearFields();
        ctx.$store.commit('toggleLoadingRedirects');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingRedirects');
        ctx.error = err;
      }
    );
  },

  updateRedirect(ctx) {
    ctx.$store.commit('toggleMuteRedirectEdit', true);
    const correctRedirectRuleData = JSON.parse(JSON.stringify(ctx.updatedRedirect));
    if (correctRedirectRuleData.redirect_url.indexOf('http://') == -1 && correctRedirectRuleData.redirect_url.indexOf('https://') == -1) {
      correctRedirectRuleData.redirect_url = `http://${correctRedirectRuleData.redirect_url}`;
    }

    // if (correctRedirectRuleData.user_profiles[correctRedirectRuleData.default_profile] && correctRedirectRuleData.user_profiles[correctRedirectRuleData.default_profile].name !== correctRedirectRuleData.default_profile) {
    //   const newDefaultProfile = correctRedirectRuleData.user_profiles[correctRedirectRuleData.default_profile];
    //   delete correctRedirectRuleData.user_profiles[correctRedirectRuleData.default_profile];
    //   correctRedirectRuleData.user_profiles[newDefaultProfile.name] = newDefaultProfile;
    //   correctRedirectRuleData.default_profile = newDefaultProfile.name;
    // }

    const updateRedirectQuery = {
      action: 'U',
      items: { [correctRedirectRuleData.id]: correctRedirectRuleData }
    };
    Vue.axios.post(`${API_URL || ''}/api/network/redirects`, updateRedirectQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          // this.getRedirectsRule(ctx);
          this.getRedirectsRule(ctx, true);
          redirectPollingService.hadleOperationResponse(response.data.data.items);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.disableEditMode();
        ctx.$store.commit('toggleMuteRedirectEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteRedirectEdit', false);
        ctx.error = err;
      }
    );
  },
  deleteRedirect(ctx) {
    const deleteRedirectQuery = { action: 'D', items: { [ctx.updatedRedirect.id]: {} } };
    Vue.axios.post(`${API_URL || ''}/api/network/redirects`, deleteRedirectQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setRedirectEditId', null);
          ctx.deletingRedirectModal = false;
          redirectPollingService.hadleOperationResponse(response.data.data.items);
          // this.getRedirectsRule(ctx);
          this.getRedirectsRule(ctx, true);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  }
};
