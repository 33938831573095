var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WTable',{staticStyle:{"height":"calc(70vh)"},attrs:{"max-height":"calc(70vh - 130px)","mode":"remote","columns":_vm.columns,"rows":_vm.rows,"totalRows":_vm.totalItems,"isDisabled":_vm.isDisabled,"bordered":false,"withToggleColumnVisibilityMode":!_vm.isOneColMode,"row-style-class":_vm.rowStyleClassFn,"sort-options":{
    enabled: !_vm.isOneColMode,
    sortState: [_vm.sortState]
  },"select-options":{
    enabled: _vm.isSelectionRowsModeEnabled,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
  },"pagination-options":{
    enabled: !_vm.isOneColMode,
    remoteMode: false,
    mode: 'records', // pages, records
    position: 'bottom',
    perPage: _vm.perPage,
    perPageDropdownEnabled: true,
    perPageDropdown: [5, 10, 20, 40, 50],
    dropdownAllowAll: false,
    jumpFirstOrLast : true,
    firstLabel: _vm.$t('general.pagination.firstPage'),
    lastLabel: _vm.$t('general.pagination.lastPage'),
    nextLabel: _vm.$t('general.pagination.nextPage'),
    prevLabel: _vm.$t('general.pagination.prevPage'),
    rowsPerPageLabel: _vm.$t('general.pagination.rowsPerPage'),
    pageLabel: _vm.$t('general.pagination.page'),
    ofLabel: _vm.$t('general.pagination.of'),
    allLabel: _vm.$t('general.pagination.all')
  },"isLoading":_vm.isDataLoading},on:{"update:isLoading":function($event){_vm.isDataLoading=$event},"update:is-loading":function($event){_vm.isDataLoading=$event},"update:columns":_vm.handleUpdateColumns,"on-selected-rows-change":_vm.handleSelectedRowsChange,"on-select-all":_vm.handleSelectedAllRowsChange,"on-cell-click":_vm.handleCellClick,"on-page-change":_vm.handlePageChange,"on-per-page-change":_vm.handlePerPageChange,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-menu",fn:function(){return [_vm._t("table-menu")]},proxy:true},{key:"table-row",fn:function(props){return [(props.column.field === 'status')?[_c('StatusCell',{attrs:{"props":props}})]:(props.column.field === 'name')?[_c('NameCell',{attrs:{"props":props}})]:(props.column.field === 'id')?[_c('IDCell',{attrs:{"props":props}})]:(props.column.field === 'ssid')?[_c('SSIDCell',{attrs:{"props":props}})]:(props.column.field === 'security')?[_c('SecurityCell',{attrs:{"props":props}})]:[_vm._v(" "+_vm._s(props.formattedRow[props.column.field] ? props.formattedRow[props.column.field] : '-')+" ")]]}},{key:"table-actions-bottom",fn:function(){return [_vm._t("table-actions-bottom")]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }