<template>
  <WDropdown
    @close="handleCloseDropdown"
    @open="handleOpenDropdown"
    :isOpen="isDropdownOpen"
  >
    <template v-slot:button-inner-content>
      <WInput
        v-model.trim="query"
        :placeholder="$t('general.searchBarPlaceholder')"
        id="search-bar"
        :customClass="['search-bar', isLoadingClass]"
        :disabled="isLoading"
        autocomplete="one-time-code"
      >
      </WInput>
      <button
        v-show="query"
        class="reset-button"
        @click.stop="reset"
        :disabled="isLoading"
      >
        <i class="fa fa-close"></i>
      </button>
    </template>
    <template v-slot:inner-content>
      <div
        class="list-wrapper"
        :class="[isLoadingClass]"
      >
        <div class="section history" v-if="reversedRequestsHistory.length">
          <h5 class="muted">{{ $t('general.historyRequests') }}</h5>
          <WList :fixedItems="{ maxItems: 3 }">
            <li v-for="request in reversedRequestsHistory"
              :key="request.id"
              class="list-item"
              @click="handleClickHistory(request.value)"
            >
              <span class="title" :title="request.value">
                {{ request.value }}
              </span>
            </li>
          </WList>

        </div>
        <div class="section access-points">
          <h5 class="muted">{{ $t('aps.header') }}</h5>
          <div class="muted" v-if="!cpeResults.length">
            {{ $t('general.noResultForSearch') }}
          </div>
          <WList
            v-if="cpeResults.length"
            :fixedItems="{ maxItems: 5 }"
          >
            <li v-for="cpe in cpeResults"
              :key="cpe.id"
              class="list-item"
              @click="redirectTo('Statcpes', cpe.foundSubstr)"
              >
              <div class="icons-bar">
                <i class="fa fa-gear" @click.stop="redirectTo('Aps', cpe.foundSubstr)"/>
                <i class="fa fa-bar-chart" @click.stop="redirectTo('Statcpes', cpe.foundSubstr)"/>
              </div>
              <span class="title" :title="cpe.foundSubstr">
                {{ cpe.foundSubstr }}
              </span>
            </li>
          </WList>
        </div>
        <div class="section clients">
          <h5 class="muted">{{ $t('aps.clients') }}</h5>
          <div class="muted" v-if="!clientsResults.length">
            {{ $t('general.noResultForSearch') }}
          </div>
          <WList
            v-if="clientsResults.length"
            :fixedItems="{ maxItems: 5 }"
          >
            <li v-for="client in clientsResults"
              :key="client.id"
              class="list-item"
              @click="redirectTo('Clients', client.foundSubstr)"
              >
              <div class="icons-bar">
                <i class="fa fa-bar-chart" @click.stop="redirectTo('Clients', client.foundSubstr)"/>
              </div>
              <span class="title" :title="client.foundSubstr">
                {{ client.foundSubstr }}
              </span>
            </li>
          </WList>
        </div>
      </div>
    </template>
  </WDropdown>
</template>

<script>
import { debounceMixin } from '@/mixins';
import { SearchBarHistoryMaster } from '@/utils';
import cpeService from '@/services/cpeService';
import clientService from '@/services/clientService';

export default {
  name: 'SearchBar',
  mixins: [debounceMixin],
  data() {
    return {
      isLoading: false,
      isDropdownOpen: false,
      query: '',
      requestsHistory: [],
      cpeResults: [],
      clientsResults: []
    };
  },
  watch: {
    query(newQueryValue) {
      this.debounce(async () => {
        if (!newQueryValue) {
          return;
        }

        if (!this.isDropdownOpen) {
          this.handleOpenDropdown();
        }

        this.isLoading = true;

        const [cpeResults, clientsResults] = await Promise.all([
          this.getCpes(newQueryValue),
          this.getClients(newQueryValue)
        ]);

        this.cpeResults = cpeResults;
        this.clientsResults = clientsResults;

        this.saveHistory(newQueryValue);

        this.isLoading = false;
      }, 1000);
    }
  },
  computed: {
    isLoadingClass() {
      return this.isLoading && 'loading';
    },
    reversedRequestsHistory() {
      // Создаем копию массива и изменяем порядок элементов наоборот
      return this.requestsHistory.slice().reverse();
    }
  },
  methods: {
    handleOpenDropdown() {
      this.isDropdownOpen = true;
    },
    handleCloseDropdown() {
      this.isDropdownOpen = false;
    },
    reset() {
      this.query = '';
      this.clientsResults = [];
      this.cpeResults = [];

      this.handleCloseDropdown();
    },
    saveHistory(query) {
      const hasAlreadyBeenAdded = this.requestsHistory.find(historyElement => historyElement.value === query);

      // если в history уже есть такой запрос, то не дублируем
      if (hasAlreadyBeenAdded) {
        return;
      }

      this.requestsHistory.push({ id: Date.now(), value: query });

      // макс 10 штук
      const sliced = this.requestsHistory.slice(-10);

      SearchBarHistoryMaster.setCachedHistory(sliced);

      this.requestsHistory = sliced;
    },
    handleClickHistory(historyQuery) {
      this.query = historyQuery;
    },
    searchSubstr(searchQuery, searchElements) {
      // Преобразование входной строки запроса, удаляя символы ":" и "."
      const formattedSearch = searchQuery.replace(/[.:]/g, '').toLowerCase();

      // Проходим по каждому элементу массива поиска
      // eslint-disable-next-line no-restricted-syntax
      for (const element of searchElements) {
        // Преобразование элемента массива, удаляя символы ":" и "."
        const formattedElement = element.replace(/[.:]/g, '').toLowerCase();
        // Если элемент массива содержит строку запроса, возвращаем его
        if (formattedElement.includes(formattedSearch)) {
          return element;
        }
      }
      // Если ни один элемент массива не содержит строку запроса, возвращаем undefined
      return undefined;
    },
    redirectTo(toRouteName, searchQuery) {
      // Aps
      // Statcpes
      // Clients
      if (this.$route.name === toRouteName && this.$route.query.searchQuery === searchQuery) {
        return;
      }

      this.$router.push({ name: toRouteName, query: { searchQuery } });
    },
    async getCpes(searchQuery) {
      const query = searchQuery.toLowerCase();

      let result;

      await cpeService.getAllCpesV2(this, query, {
        onSuccess: (allCpes) => {
          result = allCpes
            .map(cpe => {
              const { id } = cpe;
              const cpeName = cpe.name;
              const modelName = cpe.model.name;
              const ip = cpe.state.network.ipaddr;
              const mac = cpe.state.network.macaddr;

              const cpeObj = {
                id,
                cpeName,
                modelName,
                ip,
                mac
              };

              const foundSubstr = this.searchSubstr(searchQuery, [cpeName, modelName, ip, mac]);

              // Если найдена подстрока, добавляем элемент с новым свойством foundSubstr
              if (foundSubstr) {
                const updatedCpe = { ...cpeObj, foundSubstr };
                return updatedCpe;
              }

              return cpeObj;
            })
            // отфильтровываеет те, у кого нет foundSubstr, потому что эндпоинт /api/cpes еще и по id ищет, а нам это не нужно
            .filter(cpe => cpe.foundSubstr);
        }
      });

      return result;
    },
    async getClients(searchQuery) {
      const query = searchQuery.toLowerCase();

      let result;

      await clientService.getAllClients(query, {
        onSuccess: (allClients) => {
          result = allClients.map(client => {
            const { id } = client;
            const { ip } = client;
            const mac = client.mac_addr;

            const clientObj = {
              id,
              ip,
              mac
            };

            const foundSubstr = this.searchSubstr(searchQuery, [ip, mac]);

            // Если найдена подстрока, добавляем элемент с новым свойством foundSubstr
            if (foundSubstr) {
              const updatedClient = { ...clientObj, foundSubstr };
              return updatedClient;
            }

            return clientObj;
          });
        }
      });

      return result;
    }
  },
  created() {
  },
  mounted() {
    this.requestsHistory = SearchBarHistoryMaster.getCachedHistory();
  }
};
</script>

<style lang="css" scoped>
.list-wrapper {
  min-width: 260px;
  max-width: 260px;
  padding: 20px 24px;
  background-color: var(--header-bg-color);
  color: var(--header-font-color);
  box-shadow: var(--box-shadow);
  border-radius: 4px;

  position: relative;
}

.list-wrapper.loading::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: progress;
}

.list-item {
  display: flex;
  align-items: center;
  font-size: 14px;

  padding: 0;
  cursor: pointer;
  transition: var(--transition);
}

.list-item:not(:last-of-type) {
  margin-bottom: 8px;
}

.list-item.selected {
  position: relative;
}

.list-item.selected::before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: var(--brand-success);
  opacity: .5;
  border-radius: 4px;
  position: absolute;
}

.icons-bar {
  margin-right: 12px;
  flex: 0 0 auto;
}

.icons-bar i:not(:last-of-type) {
  margin-right: 8px;
}

.icons-bar i:hover {
  transition: var(--transition);
  opacity: var(--opacity);
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title:hover {
  transition: var(--transition);
  opacity: var(--opacity);
}

.muted {
  color: var(--gray-light);
}

.reset-button {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(0%, -50%);

  color: var(--header-font-color);
  background: transparent;
  border: none;

  transition: var(--transition);
}

.reset-button:hover {
  color: var(--brand-danger);
}

.section:not(:last-of-type) {
  margin-bottom: 16px;
}
</style>
<style lang="css">
/* кастомные стили хедера для WInput */
.search-bar {
  background-color: var(--header-bg-color) !important;
  border-radius: 8px !important;
  color: var(--header-font-color) !important;
  border-color: var(--header-font-color) !important;
}

.search-bar.loading {
  cursor: progress !important;
}

.search-bar::placeholder {
  color: var(--header-accent-color) !important;
}
</style>
