<template>
  <section>
    <div class="interface-block mb-1">
      <div class="setting-block-header" @click.prevent="showLogStatLbs">
        <div class="setting-block-status">
          <p class="h6 mb-h pr-h setting-block-title">{{ $t('aps.statAndRadarCaptionInModalBlock') }}</p>
          <div v-if="currentCpeConfig">
            <span v-if="currentCpeConfig.stats_config.enabled" class="badge mr-q badge-success">
              {{ $t('aps.Stats') }}: {{ $t('general.on') }}
              <span v-if="currentCpeConfig.stats_config.reportperiod">
                ({{ currentCpeConfig.stats_config.reportperiod }} {{ $t('general.sec') }})
              </span>
            </span>
            <span v-if="currentCpeConfig.log_config.enabled" class="badge mr-q badge-success">
              {{ $t('aps.Log') }}: {{ $t('general.on') }}
            </span>
            <span v-if="currentCpeConfig.lbs_config.enabled" class="badge mr-q badge-success">
              Wifi Radar: {{ $t('general.on') }}
              <span v-if="currentCpeConfig.lbs_config.reportperiod">
                ({{ currentCpeConfig.lbs_config.reportperiod }} {{ $t('general.sec') }})
              </span>
            </span>
            <span v-if="currentCpeConfig.lbs_config.enabled">
              <span v-if="currentCpeConfig.lbs_config.filtermode === 'WhiteList'" class="badge mr-q badge-info">
                {{ $t('aps.wifiRadar') }} {{ $t('general.filter') }}: {{ $t('aps.whitelist') }}
              </span>
              <span
                v-if="currentCpeConfig.lbs_config.filtermode === 'BlackList'"
                class="badge mr-q badge-dark"
              >
                {{ $t('aps.wifiRadar') }} {{ $t('general.filter') }}: {{ $t('aps.blacklist') }}
              </span>
              <span
                v-if="
                  (currentCpeConfig.lbs_config.filtermode === '' || currentCpeConfig.lbs_config.filtermode === 'None')
                "
                class="badge mr-q badge-default"
              >
                {{ $t('aps.wifiRadar') }} {{ $t('general.filter') }}: {{ $t('aps.none') }}
              </span>
            </span>
            <span
              v-if="
                currentCpeConfig.hasOwnProperty('dhcpcap_config') &&
                currentCpeConfig.dhcpcap_config.hasOwnProperty('enabled') &&
                currentCpeConfig.dhcpcap_config.enabled
              "
              class="badge mr-q badge-success"
            >
              {{ $t('aps.dhcpPacketCapture') }}: {{ $t('general.on') }}
            </span>
            <span
              v-if="
                currentCpeConfig.hasOwnProperty('wmsnmpd') &&
                currentCpeConfig.wmsnmpd.hasOwnProperty('default') &&
                currentCpeConfig.wmsnmpd.default.hasOwnProperty('enabled') &&
                currentCpeConfig.wmsnmpd.default.enabled === true
              "
              class="badge mr-q badge-success"
            >
              {{ $t('aps.snmp') }}: {{ $t('general.on') }}
            </span>
            <span
              v-if="currentCpeConfig.lbs_config.enabled &&
                currentCpeConfig.lbs_config.hasOwnProperty('emptywatcher') &&
                currentCpeConfig.lbs_config.emptywatcher
              "
              class="badge mr-q badge-success"
            >
              <span>{{ $t('aps.emptyWatcher') }}: {{ $t('general.on') }}</span>
              <!--              <span>-->
              <!--                ({{ $t('aps.maxQuietShort') }}: {{ currentCpeConfig.lbs_config.maxquiet }}-->
              <!--              </span>-->
              <!--              <span>-->
              <!--                {{ $t('aps.maxCacheQueueShort') }}: {{ currentCpeConfig.lbs_config.maxcachequeue }})-->
              <!--              </span>-->
            </span>
            <!--   config_not_send  currentCpe.config_not_send-->
            <span
              v-if="currentCpe !== undefined && currentCpe.hasOwnProperty('config_not_send')"
              class="badge mr-q badge-success"
            >
              <span v-if="currentCpe.config_not_send === true">
                {{ $t('aps.configNotSendCaption') }}: {{ $t('general.off') }}
              </span>
              <span v-else>{{ $t('aps.configNotSendCaption') }}: {{ $t('general.on') }}</span>
            </span>
          </div>
        </div>
        <div class="setting-block-buttons">
          <WButton link v-if="!isEditBlockShow">
            <i class="icon-arrow-left"></i>
          </WButton>
          <WButton link v-if="isEditBlockShow">
            <i class="icon-arrow-down"></i>
          </WButton>
        </div>
      </div>

      <transition name="interface">
        <div v-if="isEditBlockShow" class="mt-1">
          <div class="form-group mb-0">
            <Switch-component
              v-model="updatedCpeConfig.log_config.enabled"
              :disabled="isDisable"
              :label="$t('aps.logConfig')"
              :id="'log-config'"
            />
          </div>
          <transition name="interface">
            <div class="mb-3" v-if="updatedCpeConfig.log_config.enabled">
              <div class="form-group mb-1">
                <label for="log_ip">Syslog {{ $t('general.server') }}</label>
                <input
                  type="text"
                  :disabled="isDisable"
                  name="log_ip"
                  id="log_ip"
                  :placeholder="$t('aps.inputForChangeDefaultServerPlaceholder')"
                  class="form-control form-control-sm"
                  :class="{ input: true, 'is-danger': errors.has('log_ip') }"
                  v-model="updatedCpeConfig.log_config.log_ip"
                />
              </div>

              <div class="form-group mt-1 mb-2">
                <label for="log_port">Syslog {{ $t('general.server') }} {{ $t('general.port') }}</label>
                <input
                  type="text"
                  :disabled="isDisable || !updatedCpeConfig.log_config.log_ip"
                  name="log_port"
                  id="log_port"
                  v-validate="'between:0,65536'"
                  :placeholder="$t('aps.inputForChangeDefaultServerPortPlaceholder')"
                  class="form-control form-control-sm"
                  :class="{ input: true, 'is-danger': errors.has('log_port') }"
                  v-model.number="updatedCpeConfig.log_config.log_port"
                />
              </div>
            </div>
          </transition>

          <div class="form-group mt-2 mb-0">
            <Switch-component
              v-model="updatedCpeConfig.stats_config.enabled"
              :disabled="isDisable"
              :label="$t('aps.statConfig')"
              :capture="$t('aps.statConfigCapture')"
              :id="'stat-config'"
            />
          </div>
          <transition name="interface">
            <div class="form-group mb-3" v-if="updatedCpeConfig.stats_config.enabled">
              <label for="stat_reportperiod">{{ $t('aps.reportPeriod') }}</label>
              <input
                type="text"
                :disabled="isDisable"
                name="stat_reportperiod"
                id="stat_reportperiod"
                v-validate="'numeric'"
                :placeholder="$t('aps.inputForChangeDefaultPlaceholder')"
                class="form-control form-control-sm"
                :class="{ input: true, 'is-danger': errors.has('stat_reportperiod') }"
                v-model.number="updatedCpeConfig.stats_config.reportperiod"
              />
            </div>
          </transition>

          <div class="form-group mb-0 mt-2">
            <Switch-component
              v-model="updatedCpeConfig.lbs_config.enabled"
              :disabled="isDisable"
              :label="$t('aps.lbsConfig')"
              :id="'lbs-config'"
            />
          </div>
          <transition name="interface">
            <div class="mb-3" v-if="updatedCpeConfig.lbs_config.enabled">
              <div class="form-group">
                <label for="lbs_reportperiod">{{ $t('aps.reportPeriod') }}</label>
                <input
                  type="text"
                  :disabled="isDisable"
                  name="lbs_reportperiod"
                  id="lbs_reportperiod"
                  v-validate="'numeric'"
                  :placeholder="$t('aps.inputForChangeDefaultPlaceholder')"
                  class="form-control form-control-sm"
                  :class="{ input: true, 'is-danger': errors.has('lbs_reportperiod') }"
                  v-model.number="updatedCpeConfig.lbs_config.reportperiod"
                />
              </div>
              <div class="form-group mt-0">
                <label for="lbs_clienttimeout">{{ $t('aps.clientTimeout') }}</label>
                <input
                  type="text"
                  name="lbs_clienttimeout"
                  id="lbs_clienttimeout"
                  v-validate="'numeric'"
                  :disabled="isDisable"
                  :placeholder="$t('aps.inputForChangeDefaultPlaceholder')"
                  class="form-control form-control-sm"
                  :class="{ input: true, 'is-danger': errors.has('lbs_clienttimeout') }"
                  v-model.number="updatedCpeConfig.lbs_config.clienttimeout"
                />
              </div>
              <label for="filtermode">{{ $t('aps.LBSFilterMode') }}</label>
              <div class="form-group mb-0">
                <select
                  id="filtermode"
                  class="form-control"
                  v-model="updatedCpeConfig.lbs_config.filtermode"
                  :disabled="isDisable || !updatedCpeConfig.lbs_config.enabled"
                  :class="{ 'select-disabled': !updatedCpeConfig.lbs_config.enabled || isDisable }"
                >
                  <option
                    value="None"
                    :selected="
                      updatedCpeConfig.lbs_config.filtermode === '' || updatedCpeConfig.lbs_config.filtermode === 'None'
                    "
                  >
                    {{ $t('aps.none') }}
                  </option>
                  <option value="WhiteList" :selected="updatedCpeConfig.lbs_config.filtermode === 'WhiteList'">
                    {{ $t('aps.whitelist') }}
                  </option>
                  <option value="BlackList" :selected="updatedCpeConfig.lbs_config.filtermode === 'BlackList'">
                    {{ $t('aps.blacklist') }}
                  </option>
                </select>
              </div>
              <div
                class="form-group mt-1"
                v-if="
                  updatedCpeConfig.lbs_config.filtermode === 'WhiteList' ||
                  updatedCpeConfig.lbs_config.filtermode === 'BlackList'
                "
              >
                <!-- <label for="security">MAC address</label> -->
                <div class="input-group">
                  <input
                    type="text"
                    name="mac"
                    @input="enableSaveChanges = true"
                    class="form-control"
                    placeholder="00:00:00:00:00:00"
                    :disabled="isDisable || !updatedCpeConfig.lbs_config.enabled"
                    v-validate="'mac'"
                    @keyup.13="addMacAddress"
                    data-vv-validate-on="input"
                    v-model="newMacAddress"
                    :class="{ input: true, 'is-danger': errors.has('mac') }"
                  />
                  <span class="input-group-btn">
                    <WButton success
                      customClass="mb-0"
                      @click="addMacAddress"
                      :disabled="this.newMacAddress == ''"
                    >
                      {{ $t('general.add') }}
                    </WButton>
                  </span>
                </div>
              </div>

              <div class="ml-h mb-q" v-if="updatedCpeConfig.lbs_config.filtermode !== 'None'">
                <small v-if="updatedCpeConfig.lbs_config.filtermode === 'WhiteList'">
                  <strong>{{ $t('aps.currentWhitelistItems') }}: {{ lbsFilterList.whitelist.length }}</strong>
                </small>
                <small v-if="updatedCpeConfig.lbs_config.filtermode === 'BlackList'">
                  <strong>{{ $t('aps.currentBlacklistItems') }}: {{ lbsFilterList.blacklist.length }}</strong>
                </small>
              </div>
              <ul class="items_list p-0 ml-h">
                <li
                  v-for="(mac, index) in lbsFilterList.whitelist"
                  :key="mac"
                  v-if="updatedCpeConfig.lbs_config.filtermode === 'WhiteList'"
                >
                  <small
                    data-list="whitelist"
                    :data-index="index"
                    :data-mac="mac"
                    :class="{ 'item-for-delete': !isDisable }"
                    @click="removeMac"
                    class="text-uppercase"
                  >
                    {{ mac }}
                    <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
                  </small>
                </li>
                <li
                  v-for="(mac, index) in lbsFilterList.blacklist"
                  :key="mac"
                  v-if="updatedCpeConfig.lbs_config.filtermode === 'BlackList'"
                >
                  <small
                    data-list="blacklist"
                    :data-index="index"
                    :data-mac="mac"
                    :class="{ 'item-for-delete': !isDisable }"
                    @click="removeMac"
                    class="text-uppercase"
                  >
                    {{ mac }}
                    <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
                  </small>
                </li>
              </ul>
              <div class="form-group mt-1 mb-0" v-if="updatedCpeConfig.lbs_config.hasOwnProperty('maxcachequeue')">
                <div class="form-group mt-0">
                  <label for="lbs_emptywatcher_maxcachequeue">
                    {{ $t('aps.maxCacheQueue') }}
                    <small class="ml-q text-muted">{{ $t('aps.maxCacheQueueCaption') }}</small>
                  </label>
                  <input
                    type="number"
                    :disabled="isDisable"
                    name="lbs_emptywatcher_maxcachequeue"
                    id="lbs_emptywatcher_maxcachequeue"
                    v-validate="'numeric'"
                    class="form-control form-control-sm"
                    :class="{ input: true, 'is-danger': errors.has('lbs_emptywatcher_maxcachequeue') }"
                    v-model.number="updatedCpeConfig.lbs_config.maxcachequeue"
                  />
                </div>
              </div>
              <div class="form-group mt-1 mb-0" v-if="updatedCpeConfig.lbs_config.hasOwnProperty('emptywatcher')">
                <Switch-component
                  v-model="updatedCpeConfig.lbs_config.emptywatcher"
                  :disabled="isDisable"
                  :label="$t('aps.emptyWatcher')"
                  :id="'lbs-config-emptywatcher'"
                />
                <info class="info-tooltip-in-lbs-config" :content="$t('aps.emptyWatcherInfoTooltip')" />
              </div>
              <transition>
                <div class="form-group mb-0" v-if="updatedCpeConfig.lbs_config.emptywatcher === true">
                  <div class="form-group">
                    <label for="lbs_emptywatcher_maxquiet">
                      {{ $t('aps.maxQuiet') }}
                      <small class="ml-q text-muted">{{ $t('aps.maxQuietCaption') }}</small>
                    </label>
                    <input
                      type="number"
                      :disabled="isDisable"
                      name="lbs_emptywatcher_maxquiet"
                      id="lbs_emptywatcher_maxquiet"
                      v-validate="'numeric'"
                      class="form-control form-control-sm"
                      :class="{ input: true, 'is-danger': errors.has('lbs_emptywatcher_maxquiet') }"
                      v-model.number="updatedCpeConfig.lbs_config.maxquiet"
                    />
                  </div>
                </div>
              </transition>
            </div>
          </transition>
          <div
            class="form-group mt-1 mb-0"
            v-if="
              updatedCpeConfig.hasOwnProperty('dhcpcap_config') &&
              updatedCpeConfig.dhcpcap_config.hasOwnProperty('enabled')
            "
          >
            <Switch-component
              v-model="updatedCpeConfig.dhcpcap_config.enabled"
              :disabled="isDisable"
              :label="$t('aps.dhcpPacketCapture')"
              :id="'dhcp-capture'"
            />
          </div>
          <!--          SNMP Settings-->
          <div
            class="mt-2"
            v-if="
              updatedCpeConfig.hasOwnProperty('wmsnmpd') &&
              updatedCpeConfig.wmsnmpd.hasOwnProperty('default') &&
              updatedCpeConfig.wmsnmpd.default.hasOwnProperty('enabled')
            "
          >
            <!--          switcher-->
            <div class="form-group mt-1 mb-0">
              <Switch-component
                v-model="updatedCpeConfig.wmsnmpd.default.enabled"
                :disabled="isDisable"
                :label="$t('aps.snmp')"
                :id="'snmp-switcher'"
              />
            </div>
            <transition>
              <div class="form-group mb-0 animated fadeIn" v-if="updatedCpeConfig.wmsnmpd.default.enabled === true">
                <!--          community-->
                <div class="form-group">
                  <label for="snmp_community">
                    {{ $t('aps.snmpCommunity') }}
                    <small class="ml-q text-muted"></small>
                  </label>
                  <input
                    type="string"
                    :disabled="isDisable"
                    name="snmp_community"
                    id="snmp_community"
                    class="form-control form-control-sm"
                    :class="{ input: true, 'is-danger': errors.has('snmp_community') }"
                    v-model="updatedCpeConfig.wmsnmpd.default.community"
                  />
                </div>
                <!--              location-->
                <div class="form-group">
                  <label for="snmp_location">
                    {{ $t('aps.snmpLocation') }}
                    <small class="ml-q text-muted"></small>
                  </label>
                  <input
                    type="string"
                    :disabled="isDisable"
                    name="snmp_location"
                    id="snmp_location"
                    class="form-control form-control-sm"
                    :class="{ input: true, 'is-danger': errors.has('snmp_location') }"
                    v-model="updatedCpeConfig.wmsnmpd.default.location"
                  />
                </div>
                <!--          listen_interface-->
                <div class="form-group">
                  <label for="snmp_listen_interface">
                    {{ $t('aps.snmpListenInterface') }}
                    <small class="ml-q text-muted"></small>
                  </label>
                  <input
                    type="string"
                    :disabled="isDisable"
                    name="snmp_listen_interface"
                    id="snmp_listen_interface"
                    class="form-control form-control-sm"
                    :class="{ input: true, 'is-danger': errors.has('snmp_listen_interface') }"
                    v-model="updatedCpeConfig.wmsnmpd.default.listen_interface"
                  />
                </div>
                <!--              snmp_interfaces-->
                <div class="form-group">
                  <label for="snmp_interfaces">
                    {{ $t('aps.snmpinterfaces') }}
                    <small class="ml-q text-muted"></small>
                  </label>
                  <div class="input-group">
                    <input
                      type="string"
                      :disabled="isDisable"
                      name="snmp_interfaces"
                      id="snmp_interfaces"
                      class="form-control form-control-sm"
                      :class="{ input: true, 'is-danger': errors.has('snmp_interfaces') }"
                      v-model="newWmsnmpdinterface"
                    />
                    <span class="input-group-btn">
                      <WButton success
                        customClass="mb-0"
                        @click="addSnmpInterfaceItem"
                        :disabled="this.newWmsnmpdinterface == ''"
                      >
                        {{ $t('general.add') }}
                      </WButton>
                    </span>
                  </div>
                  <small>
                    <strong>{{ $t('aps.snmpinterfacesListItems') }}: {{ snmpinterfacesListCounter }}</strong>
                  </small>
                  <ul class="items_list p-0 ml-h">
                    <li
                      v-for="(interfaceItem, index) in updatedCpeConfig.wmsnmpd.default.interfaces"
                      :key="interfaceItem"
                    >
                      <small
                        data-list="interfaces"
                        :data-index="index"
                        :data-interface="interfaceItem"
                        :class="{ 'item-for-delete': !isDisable }"
                        @click="removeSnmpInterfaceItem(index)"
                      >
                        {{ interfaceItem }}
                        <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
                      </small>
                    </li>
                  </ul>
                </div>
              </div>
            </transition>
          </div>

          <!--          config_not_send  updatedCpe.config_not_send-->
          <div class="mt-2" v-if="updatedCpe !== undefined && updatedCpe.hasOwnProperty('config_not_send')">
            <Switch-component
              inverseSwitcherEnabledLogic
              v-model="updatedCpe.config_not_send"
              :disabled="isDisable"
              :label="$t('aps.configNotSendCaption')"
              :id="'config_not_send'"
            />
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import commonService from '../../services/commonService';
import SwitchComponent from '../Universal/Switch-component.vue';
import Info from '../Universal/info-icon.vue';


export default {
  name: 'StatLogLbsConfig',
  components: { SwitchComponent, Info },
  inject: ['$validator'],
  props: {
    updatedCpeConfig: {
      type: Object
    },
    currentCpeConfig: {
      type: Object
    },
    // два пропса ниже нужны  были временно для прокидвания сюда данных для настройки snmp
    // updatedCpeConfigSNMP: {
    //   type: Object,
    //   default() {
    //     return {};
    //   }
    // },
    // currentCpeConfigSNMP: {
    //   type: Object,
    //   default() {
    //     return {};
    //   }
    // },
    lbsFilterList: {
      type: Object
    },
    isDisable: {
      type: Boolean
    },
    isOperator: {
      type: Boolean
    },
    isEditShow: {
      type: Boolean
    },
    updatedCpe: {
      default: undefined
    },
    currentCpe: {
      default: undefined
    }
  },
  data() {
    return {
      newWmsnmpdinterface: '',
      isEditBlockShow: this.isEditShow,
      newMacAddress: '',
    };
  },
  watch: {
    logIp() {
      if (!this.updatedCpeConfig.log_config.log_ip) {
        this.updatedCpeConfig.log_config.log_port = '';
      }
    }
  },
  computed: {
    snmpinterfacesListCounter() {
      // ловим ошибку если в interfaces null вместо пустого массива
      try {
        return this.updatedCpeConfig.wmsnmpd.default.interfaces.length;
      } catch (e) {
        return 0;
      }
    },
    commonService() {
      return commonService;
    },
    logIp() {
      return this.updatedCpeConfig.log_config.log_ip;
    }
  },
  methods: {
    showLogStatLbs() {
      this.isEditBlockShow = !this.isEditBlockShow;
    },
    addMacAddress() {
      this.$validator.validateAll({ mac: this.newMacAddress }).then((result) => {
        if (result) {
          if (this.updatedCpeConfig.lbs_config.filtermode === 'WhiteList' && this.newMacAddress) {
            if (this.lbsFilterList.whitelist.indexOf(this.newMacAddress) == -1) {
              this.lbsFilterList.whitelist.push(this.newMacAddress);
            } else {
              return;
            }
          }
          if (this.updatedCpeConfig.lbs_config.filtermode === 'BlackList' && this.newMacAddress) {
            if (this.lbsFilterList.blacklist.indexOf(this.newMacAddress) == -1) {
              this.lbsFilterList.blacklist.push(this.newMacAddress);
            } else {
              return;
            }
          }
          this.newMacAddress = '';
          this.$validator.reset();
        }
      });
    },
    addSnmpInterfaceItem() {
      this.createArrayForSNMPInterfacesIfItIsNull();
      if (!this.updatedCpeConfig.wmsnmpd.default.interfaces.includes(this.newWmsnmpdinterface)) {
        this.updatedCpeConfig.wmsnmpd.default.interfaces.push(this.newWmsnmpdinterface);
      } else {
        return;
      }
      this.newWmsnmpdinterface = '';
    },

    removeMac(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      const { list } = selectedNode.dataset;
      if (list === 'whitelist') {
        this.lbsFilterList.whitelist.splice(index, 1);
      }
      if (list === 'blacklist') {
        this.lbsFilterList.blacklist.splice(index, 1);
      }
    },
    removeSnmpInterfaceItem(index) {
      if (this.isDisable) {
        return;
      }
      this.updatedCpeConfig.wmsnmpd.default.interfaces.splice(index, 1);
    },
    createArrayForSNMPInterfacesIfItIsNull() {
      // если вместо пустого списка интерфейсов пришел null создаем пустой список
      if (
        this.updatedCpeConfig.hasOwnProperty('wmsnmpd') &&
        this.updatedCpeConfig.wmsnmpd.hasOwnProperty('default') &&
        this.updatedCpeConfig.wmsnmpd.default.hasOwnProperty('interfaces') &&
        this.updatedCpeConfig.wmsnmpd.default.interfaces === null
      ) {
        this.updatedCpeConfig.wmsnmpd.default.interfaces = [];
      }
    },
  },
  created() {
  }
};
</script>

<style lang="scss" scoped>
.info-tooltip-in-lbs-config {
  margin-left: 0.2em;
}
</style>
