/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы WlansTable.vue
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/

import {
  getSelectedRowValue
} from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  return [
    {
      label: $t('wlans.tableTab_status'),
      field: 'status',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.status
    },
    {
      label: $t('wlans.tableTab_name'),
      field: 'name',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.name
    },
    {
      label: 'ID',
      field: 'id',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.id
    },
    {
      label: $t('wlans.tableTab_ssid'),
      field: 'ssid',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.ssid
    },
    {
      label: $t('wlans.tableTab_security'),
      field: 'security',
      width: 'auto',
      type: 'text',
      sortable: false,
      hidden: hiddenColumnRules.security
      // visibilityDropdownSettings: {
      //   hidden: !isContentAnalyticsEnabled
      // }
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ wlansData, selectedWlansIds }) {
  return wlansData.map(wlan => {
    const {
      aaa_override,
      band_steering,
      base_location,
      beeline_accountng_type,
      blacklist,
      bss_transition,
      clients,
      cpe_ids,
      default_tunnel,
      description,
      filtermode,
      firewall,
      ft_over_ds,
      guest_control,
      hidden,
      id,
      ieee80211k,
      ieee80211v,
      l2isolate,
      load_balancing,
      name,
      nas_generate,
      nas_id,
      nas_ip_address,
      nas_port_id,
      nat,
      nat_network,
      option82cid_type,
      option82rid_type,
      option82state,
      peer_address,
      pmkcaching,
      ppsk,
      proto,
      proxy_arp,
      radius_acct_interval,
      radius_acct_mirroring,
      radius_acct_servers,
      roam80211r,
      rrm_beacon_report,
      rrm_neighbor_report,
      rssi_threshold,
      security,
      signal_connect,
      signal_drop_reason,
      signal_poll_time,
      signal_stay,
      signal_strikes,
      speed_download,
      speed_upload,
      ssid,
      status,
      tags,
      tunneling,
      vlan,
      vlans,
      whitelist,
      wmm,
      wnm_sleep_mode
    } = wlan;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: wlan,
      status,
      name,
      id,
      ssid,
      security,
      // можно ли выбирать строку чекбоксом
      // vgtDisabled: false,
      // выбрана ли строка в чекбоксе исходя из стейта, который контролирует выбранные строки
      vgtSelected: getSelectedRowValue(id, selectedWlansIds)
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
