<template>
  <div class="row h-100">
    <div class="col-lg-12 h-100 position-relative">
      <loader-spinner
        class="card h-100"
        centered
        v-if="iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess"
      ></loader-spinner>
      <div v-else class=" h-100">
        <div class="card-block">
<!--          <div class="h4 m-0">-->
<!--            {{ $t('general.step3') }}-->
<!--            <button-->
<!--              type="button"-->
<!--              class="btn btn-sm float-right btn-outline-info skip-button-in-wlan-wizard new-modern-style-btn-other-btn"-->
<!--              @click="lastStepButtonClickHandler"-->
<!--            >-->
<!--              {{ $t('wlans.wizardSkipButton') }}-->
<!--              <info :content="$t('wlans.wizardSkipButtonInfo')" />-->
<!--            </button>-->
<!--          </div>-->
          <span class="text-muted step-caption-in-wlan-wizard" :class="{ 'not-for-cisco': isCiscoModeEnabled }">
            {{ $t('wlans.dataTrafficManagement') }}
          </span>
          <div v-if="isCiscoModeEnabled">
            <small class="text-danger p-0" v-if="isCiscoModeEnabled">* {{ $t('wlans.notAvailableForCisco') }}</small>
          </div>
          <form action="">
<!--            <div name="wsettings mt-1">-->
<!--              <div>-->
<!--                <div class="row mt-2">-->
<!--                  <div class="form-group col-sm-6 mb-0">-->
<!--                    <Switch-component-->
<!--                      v-model="newWlanData.nat"-->
<!--                      @input="$validator.reset()"-->
<!--                      :label="'NAT'"-->
<!--                      id="nat"-->
<!--                      @change="checkNat"-->
<!--                    />-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div v-if="newWlanData.nat === true && newWlanData.hasOwnProperty('nat_network')">-->
<!--                  <span class="text-muted">{{ $t('wlans.natNetworkInfoMsg') }}</span>-->
<!--                  <div class="form-group mt-1" v-show="newWlanData.nat === true">-->
<!--                    <label for="nat_network_ipaddr">-->
<!--                      {{ $t('wlans.natNetworkIpaddr') }}-->
<!--                    </label>-->
<!--                    <input-->
<!--                      type="text"-->
<!--                      :key="'nat_network_ipaddr'"-->
<!--                      id="nat_network_ipaddr"-->
<!--                      autocomplete="off"-->
<!--                      name="nat_network_ipaddr"-->
<!--                      v-validate="'ipv4ForNatNetwork'"-->
<!--                      data-vv-validate-on="change"-->
<!--                      :class="{ input: true, 'is-danger': errors.has('nat_network_ipaddr') }"-->
<!--                      :placeholder="$t('wlans.natNetworkIpaddrPlaceholder')"-->
<!--                      class="form-control"-->
<!--                      v-model="newWlanData.nat_network.ipaddr"-->
<!--                    />-->
<!--                    <span v-show="errors.has('nat_network_ipaddr')" class="help is-danger">-->
<!--                      {{ errors.first('nat_network_ipaddr') }}-->
<!--                    </span>-->
<!--                  </div>-->
<!--                  <div class="form-group mt-1 mb-2" v-show="newWlanData.nat === true">-->
<!--                    <label for="nat_network_netmask">-->
<!--                      {{ $t('wlans.natNetworkNetmask') }}-->
<!--                    </label>-->
<!--                    <input-->
<!--                      type="text"-->
<!--                      :key="'nat_network_netmask'"-->
<!--                      id="nat_network_netmask"-->
<!--                      autocomplete="off"-->
<!--                      name="nat_network_netmask"-->
<!--                      v-validate="'maskForNatNetwork'"-->
<!--                      data-vv-validate-on="change"-->
<!--                      :class="{ input: true, 'is-danger': errors.has('nat_network_netmask') }"-->
<!--                      :placeholder="$t('wlans.natNetworkNetmaskPlaceholder')"-->
<!--                      class="form-control"-->
<!--                      v-model="newWlanData.nat_network.netmask"-->
<!--                    />-->
<!--                    <span v-show="errors.has('nat_network_netmask')" class="help is-danger">-->
<!--                      {{ errors.first('nat_network_netmask') }}-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div v-if="!newWlanData.nat">-->
<!--                  <div class="row mt-2">-->
<!--                    <div class="form-group col-sm-8 mb-0">-->
<!--                      <Switch-component-->
<!--                        v-model="newWlanData.tunneling"-->
<!--                        :disabled="isNatActive"-->
<!--                        :label="$t('wlans.centralized')"-->
<!--                        @change="newWlanData.default_tunnel = ''"-->
<!--                        @input="$validator.reset()"-->
<!--                        id="tunneling"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  &lt;!&ndash;                              Proto fields for EoGRE &ndash;&gt;-->
<!--                  <div class="form-group mt-1 mb-1" v-if="newWlanData.tunneling && newWlanData.hasOwnProperty('proto')">-->
<!--                    <label for="proto" class="">{{ $t('wlans.proto') }}</label>-->
<!--                    <select-->
<!--                      @change="changeProtoInSelector"-->
<!--                      class="form-control"-->
<!--                      name="proto"-->
<!--                      v-model="newWlanData.proto"-->
<!--                      id="proto"-->
<!--                      :class="{-->
<!--                        input: true,-->
<!--                        'select-disabled': !newWlanData.tunneling-->
<!--                      }"-->
<!--                    >-->
<!--                      &lt;!&ndash;                                  <option value="" disabled selected></option>&ndash;&gt;-->
<!--                      <option :value="proto.value" :key="proto.value" v-for="proto in typesOfProto">-->
<!--                        {{ proto.name }}-->
<!--                      </option>-->
<!--                    </select>-->
<!--                  </div>-->


<!--                  <div-->
<!--                    class="form-group mb-2"-->
<!--                    v-if="-->
<!--                      hostsList[0] && interfacesWithDetailsFromAllHostsAsArray.length && newWlanData.tunneling && newWlanData.proto !== 'gretap'-->
<!--                    "-->
<!--                  >-->
<!--                    <label for="default_tunnel" class="">{{ $t('wlans.interface') }}</label>-->
<!--&lt;!&ndash;                    <Multiselect&ndash;&gt;-->
<!--&lt;!&ndash;                      :customLabel="customLabelForTunnelMiltiselect"&ndash;&gt;-->
<!--&lt;!&ndash;                      v-model="newWlanData.default_tunnel"&ndash;&gt;-->
<!--&lt;!&ndash;                      :multiple="false"&ndash;&gt;-->
<!--&lt;!&ndash;                      :options="interfacesWithDetailsFromAllHostsAsArray.map(interfaceWithdetails=>interfaceWithdetails.name) || []"&ndash;&gt;-->
<!--&lt;!&ndash;                      :closeOnSelect="true"&ndash;&gt;-->
<!--&lt;!&ndash;                      :hideSelected="true"&ndash;&gt;-->
<!--&lt;!&ndash;                      v-validate="'required'"&ndash;&gt;-->
<!--&lt;!&ndash;                      :data-vv-as="$t('wlans.interface')"&ndash;&gt;-->
<!--&lt;!&ndash;                      id="default_tunnel"&ndash;&gt;-->
<!--&lt;!&ndash;                      data-vv-name="Interface"&ndash;&gt;-->
<!--&lt;!&ndash;                      :placeholder="$t('wlans.selectSingleInterface')"&ndash;&gt;-->
<!--&lt;!&ndash;                      :selectLabel="$t('aps.wlansSelectorTip')"&ndash;&gt;-->
<!--&lt;!&ndash;                      :disabled="!newWlanData.tunneling"&ndash;&gt;-->
<!--&lt;!&ndash;                      :class="{ 'multiselect-disabled': !newWlanData.tunneling}"&ndash;&gt;-->
<!--&lt;!&ndash;                      :allowEmpty="true"&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                      <template v-slot:noOptions>&ndash;&gt;-->
<!--&lt;!&ndash;                        {{ $t('general.noDataToDisplay') }}&ndash;&gt;-->
<!--&lt;!&ndash;                      </template>&ndash;&gt;-->
<!--&lt;!&ndash;                      <template v-slot:singleLabel>&ndash;&gt;-->
<!--&lt;!&ndash;                        {{labelForSelectedInTunnelMiltiselect()}}&ndash;&gt;-->
<!--&lt;!&ndash;                      </template>&ndash;&gt;-->
<!--&lt;!&ndash;                      <template v-slot:noResult>&ndash;&gt;-->
<!--&lt;!&ndash;                        {{ $t('general.noResultForSearch') }}&ndash;&gt;-->
<!--&lt;!&ndash;                      </template>&ndash;&gt;-->
<!--&lt;!&ndash;                    </Multiselect>&ndash;&gt;-->
<!--                    <Multiselect-->
<!--                      label="nameWithType"-->
<!--                      trackBy="name"-->
<!--                      v-model="newWlanDataDefaultTunnel"-->
<!--                      :multiple="false"-->
<!--                      @input="selectWlanDefaultTunnel"-->
<!--                      :options="interfacesWithDetailsFromAllHostsAsArray ||  []"-->
<!--                      :closeOnSelect="true"-->
<!--                      :hideSelected="true"-->
<!--                      v-validate="'required|multiSelectWithInterfaceNotEmpty'"-->
<!--                      :data-vv-as="$t('wlans.interface')"-->
<!--                      id="default_tunnel"-->
<!--                      data-vv-name="Interface"-->
<!--                      :placeholder="$t('wlans.selectSingleInterface')"-->
<!--                      :selectLabel="$t('aps.wlansSelectorTip')"-->
<!--                      :disabled="!newWlanData.tunneling"-->
<!--                      :class="{ 'multiselect-disabled': !newWlanData.tunneling}"-->
<!--                      :allowEmpty="true"-->
<!--                    >-->
<!--                      <template v-slot:noOptions>-->
<!--                        {{ $t('general.noDataToDisplay') }}-->
<!--                      </template>-->
<!--&lt;!&ndash;                      <template v-slot:singleLabel>&ndash;&gt;-->
<!--&lt;!&ndash;                        {{labelForSelectedInTunnelMiltiselect()}}&ndash;&gt;-->
<!--&lt;!&ndash;                      </template>&ndash;&gt;-->
<!--                      <template v-slot:noResult>-->
<!--                        {{ $t('general.noResultForSearch') }}-->
<!--                      </template>-->
<!--                    </Multiselect>-->
<!--                    <a v-if="!(!newWlanData.tunneling)" href="#"-->
<!--                       class="ml-q "-->
<!--                       @click.prevent="clearDefaultTunnel">-->
<!--                      <span>{{ $t('general.clear') }}</span>-->
<!--                    </a>-->
<!--                    <span v-show="errors.has('Interface')" class="help is-danger">-->
<!--                      {{ errors.first('Interface') }}-->
<!--                    </span>-->
<!--                    <small v-show="newWlanData.tunneling" class="help is-danger">-->
<!--                      {{ $t('wlans.bridge') }}-->
<!--                    </small>-->
<!--                  </div>-->

<!--                  <div v-else-if="(!hostsList[0] || !interfacesWithDetailsFromAllHostsAsArray.length)">-->
<!--                    {{ $t('wlans.noInterfaces') }}-->
<!--                  </div>-->




<!--&lt;!&ndash;                  <div&ndash;&gt;-->
<!--&lt;!&ndash;                    class="form-group mb-2"&ndash;&gt;-->
<!--&lt;!&ndash;                    v-if="&ndash;&gt;-->
<!--&lt;!&ndash;                      hostsList[0] && hostsList[0].interfaces && newWlanData.tunneling && newWlanData.proto !== 'gretap'&ndash;&gt;-->
<!--&lt;!&ndash;                    "&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <label for="default_tunnel" class="">{{ $t('wlans.interface') }}</label>&ndash;&gt;-->
<!--&lt;!&ndash;                    <select&ndash;&gt;-->
<!--&lt;!&ndash;                      class="form-control"&ndash;&gt;-->
<!--&lt;!&ndash;                      name="hosts_interface"&ndash;&gt;-->
<!--&lt;!&ndash;                      v-model="newWlanData.default_tunnel"&ndash;&gt;-->
<!--&lt;!&ndash;                      id="default_tunnel"&ndash;&gt;-->
<!--&lt;!&ndash;                      data-vv-name="Interface"&ndash;&gt;-->
<!--&lt;!&ndash;                      v-validate="'required'"&ndash;&gt;-->
<!--&lt;!&ndash;                      :disabled="!newWlanData.tunneling"&ndash;&gt;-->
<!--&lt;!&ndash;                      :class="{&ndash;&gt;-->
<!--&lt;!&ndash;                        input: true,&ndash;&gt;-->
<!--&lt;!&ndash;                        'is-danger': errors.has('Interface'),&ndash;&gt;-->
<!--&lt;!&ndash;                        'select-disabled': !newWlanData.tunneling&ndash;&gt;-->
<!--&lt;!&ndash;                      }"&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                      <option value="" disabled selected>{{ $t('wlans.selectInterface') }}</option>&ndash;&gt;-->
<!--&lt;!&ndash;                      <option&ndash;&gt;-->
<!--&lt;!&ndash;                        v-for="hostInterface in hostsList[0].interfaces"&ndash;&gt;-->
<!--&lt;!&ndash;                        :value="hostInterface"&ndash;&gt;-->
<!--&lt;!&ndash;                        :key="hostInterface"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        {{ hostInterface }}&ndash;&gt;-->
<!--&lt;!&ndash;                      </option>&ndash;&gt;-->
<!--&lt;!&ndash;                    </select>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span v-show="errors.has('Interface')" class="help is-danger">&ndash;&gt;-->
<!--&lt;!&ndash;                      {{ errors.first('Interface') }}&ndash;&gt;-->
<!--&lt;!&ndash;                    </span>&ndash;&gt;-->
<!--&lt;!&ndash;                    <small v-show="newWlanData.tunneling" class="help is-danger">&ndash;&gt;-->
<!--&lt;!&ndash;                      {{ $t('wlans.bridge') }}&ndash;&gt;-->
<!--&lt;!&ndash;                    </small>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->

<!--&lt;!&ndash;                  <div v-else-if="(!hostsList[0] || !hostsList[0].interfaces)">&ndash;&gt;-->
<!--&lt;!&ndash;                    {{ $t('wlans.noInterfaces') }}&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                  &lt;!&ndash;                              peerAddress aka EoGRE server address&ndash;&gt;-->
<!--                  <div-->
<!--                    class="form-group mt-1"-->
<!--                    v-if="newWlanData.hasOwnProperty('peer_address') && newWlanData.proto === 'gretap' && newWlanData.tunneling"-->
<!--                  >-->
<!--                    <label for="peerAddress">-->
<!--                      {{ $t('wlans.peerAddress') }}-->
<!--                    </label>-->
<!--                    <input-->
<!--                      key="peerAddress"-->
<!--                      type="text"-->
<!--                      :data-vv-as="$t('wlans.peerAddress')"-->
<!--                      id="peerAddress"-->
<!--                      name="peerAddress"-->
<!--                      :class="{ input: true, 'is-danger': errors.has('peerAddress') }"-->
<!--                      v-validate="'required|addressForEoGREServer'"-->
<!--                      :placeholder="$t('wlans.peerAddressPlaceholder')"-->
<!--                      data-vv-validate-on="change"-->
<!--                      class="form-control"-->
<!--                      v-model="newWlanData.peer_address"-->
<!--                      :disabled="isNatActive"-->
<!--                    />-->
<!--                    <span v-show="errors.has('peerAddress')" class="help is-danger">-->
<!--                      {{ errors.first('peerAddress') }}-->
<!--                    </span>-->
<!--                  </div>-->

<!--                  <div class="form-group mt-1">-->
<!--                    <label for="vlan">-->
<!--                      VLAN-->
<!--                      <span class="text-muted" v-if="!newWlanData.vlan">{{ $t('wlans.vlanNative') }}</span>-->
<!--                    </label>-->
<!--                    <input-->
<!--                      type="text"-->
<!--                      id="vlan"-->
<!--                      name="vlan"-->
<!--                      :class="{ input: true, 'is-danger': errors.has('vlan') }"-->
<!--                      v-validate="'numeric|between:0,4094'"-->
<!--                      :placeholder="$t('wlans.enterVLANPlaceHolder')"-->
<!--                      class="form-control"-->
<!--                      v-model="newWlanData.vlan"-->
<!--                      :disabled="isNatActive"-->
<!--                    />-->
<!--                    <span v-show="errors.has('vlan')" class="help is-danger">-->
<!--                      {{ errors.first('vlan') }}-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div v-if="newWlanData.nat">-->
<!--                  <div class="row mt-2">-->
<!--                    <div class="form-group col-sm-6 mb-0">-->
<!--                      <Switch-component-->
<!--                        v-model="newWlanData.firewall.nat_access"-->
<!--                        :label="'SSH / Web access'"-->
<!--                        @input="$validator.reset()"-->
<!--                        id="nat-access"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->


<!--            <hr >-->
<!--                        <hr >-->
<!--                        <hr >-->
            <div class="d-flex flex-row mt-1">
              <div :class="{'w-50': showDataTrafficManagementTabRightColumn, 'w-100': !showDataTrafficManagementTabRightColumn}">
                <transition name="wsettings">
                        <div :class="{'mr-3': showDataTrafficManagementTabRightColumn}">
                          <div>
                            <!--                          local Switching-->
                            <Switch-component
                              v-model="dataTrafficManagementBlocksShow.localSwitching"
                              :disabled="isDisable || dataTrafficManagementBlocksShow.localSwitching"
                              :label="$t('wlans.localSwitching')"
                              @change="dataTrafficManagementSwitchersHandler"
                              id="localSwitching-switcher"
                            />
                          </div>
                          <transition name="wsettings">
                          <div class="ml-2" v-if="dataTrafficManagementBlocksShow.localSwitching">
                            <div class="row mt-h">
                              <div class="form-group col-sm-6 mb-0">
                                <Switch-component
                                  v-model="newWlanData.nat"
                                  :disabled="isDisable"
                                  @input="$validator.reset()"
                                  :label="'NAT'"
                                  id="nat"
                                  @change="checkNat"
                                />
                              </div>
                            </div>
                            <div
                              v-if="newWlanData.nat === true && newWlanData.hasOwnProperty('nat_network')">
                              <span class="text-muted">{{ $t('wlans.natNetworkInfoMsg') }}</span>
                              <div class="form-group mt-1" v-show="newWlanData.nat === true">
                                <label for="nat_network_ipaddr">
                                  {{ $t('wlans.natNetworkIpaddr') }}
                                </label>
                                <input
                                  type="text"
                                  :key="'nat_network_ipaddr'"
                                  id="nat_network_ipaddr"
                                  autocomplete="off"
                                  name="nat_network_ipaddr"
                                  v-validate="'ipv4ForNatNetwork'"
                                  data-vv-validate-on="change"
                                  :class="{ input: true, 'is-danger': errors.has('nat_network_ipaddr') }"
                                  :placeholder="$t('wlans.natNetworkIpaddrPlaceholder')"
                                  class="form-control"
                                  v-model="newWlanData.nat_network.ipaddr"
                                  :disabled="isDisable"
                                />
                                <span v-show="errors.has('nat_network_ipaddr')"
                                      class="help is-danger">
                                  {{ errors.first('nat_network_ipaddr') }}
                                </span>
                              </div>
                              <div class="form-group mt-1 mb-2" v-show="newWlanData.nat === true">
                                <label for="nat_network_netmask">
                                  {{ $t('wlans.natNetworkNetmask') }}
                                </label>
                                <input
                                  type="text"
                                  :key="'nat_network_netmask'"
                                  id="nat_network_netmask"
                                  autocomplete="off"
                                  name="nat_network_netmask"
                                  v-validate="'maskForNatNetwork'"
                                  data-vv-validate-on="change"
                                  :class="{ input: true, 'is-danger': errors.has('nat_network_netmask') }"
                                  :placeholder="$t('wlans.natNetworkNetmaskPlaceholder')"
                                  class="form-control"
                                  v-model="newWlanData.nat_network.netmask"
                                  :disabled="isDisable"
                                />
                                <span v-show="errors.has('nat_network_netmask')"
                                      class="help is-danger">
                                  {{ errors.first('nat_network_netmask') }}
                                </span>
                              </div>
                            </div>

                            <div v-if="newWlanData.nat">
                              <div class="row mt-2">
                                <div class="form-group col-sm-6 mb-0">
                                  <Switch-component
                                    v-model="newWlanData.firewall.nat_access"
                                    :disabled="isDisable"
                                    :label="$t('wlans.SSHWebAccess')"
                                    @input="$validator.reset()"
                                    id="nat-access"
                                  />
                                </div>
                              </div>
                            </div>


                            <div class="form-group mt-1">
                              <label for="vlan">
                                VLAN
                                <span class="text-muted" v-if="!newWlanData.vlan">{{ $t('wlans.vlanNative') }}</span>
                              </label>
                              <input
                                type="text"
                                id="vlan"
                                name="vlan"
                                :class="{ input: true, 'is-danger': errors.has('vlan') }"
                                v-validate="vlanFieldValidtaionRules"
                                data-vv-validate-on="none"
                                @input="vlanForLocalSwitchingInputHandler"
                                :placeholder="$t('wlans.enterVLANPlaceHolder')"
                                class="form-control"
                                v-model="newWlanData.vlan"
                                :disabled="isDisable || isNatActive"
                              />
                              <span v-show="errors.has('vlan')" class="help is-danger">
                                  {{ errors.first('vlan') }}
                                </span>
                            </div>
                            <div class="form-group" v-if="showDynamicVLANInputField">
                              <!-- DynamicVLAN field -->
                              <WInput md :label="$t('wlans.DynamicVLAN')"
                                      value=""
                                      :disabled="isDisable || isNatActive"
                                      id="DynamicVLAN"
                                      v-validate="'required|dynamicVLANFieldValidator'"
                                      name="DynamicVLAN"
                                      :data-vv-as="$t('wlans.DynamicVLAN')"
                                      v-model="dynamicVlansString"
                                      :errorText="errors.first('DynamicVLAN')"
                                      :placeholder="$t('wlans.DynamicVLANPlaceholder')">
                              </WInput>
                              <!-- end DynamicVLAN field -->
                            </div>
                          </div>
                          </transition>

                          <div>
                            <!--                          central Switching-->
                            <Switch-component
                              v-model="dataTrafficManagementBlocksShow.centralSwitching"
                              :disabled="isDisable || dataTrafficManagementBlocksShow.centralSwitching"
                              :label="$t('wlans.centralSwitching')"
                               @change="dataTrafficManagementSwitchersHandler"
                              id="centralSwitching-switcher"
                            />
                          </div>
                          <transition name="wsettings">
                          <div class="ml-1" v-if="dataTrafficManagementBlocksShow.centralSwitching">
                            <div v-if="!newWlanData.nat">
<!--                              <div class="row " v-if="">-->
<!--                                <div class="form-group col-sm-6 mb-0">-->
<!--                                  <Switch-component-->
<!--                                    v-model="newWlanData.tunneling"-->
<!--                                    :disabled="isDisable || isNatActive"-->
<!--                                    :label="$t('wlans.centralized')"-->
<!--                                    @change="changeTunnelingSelector"-->
<!--                                    @input="$validator.reset()"-->
<!--                                    id="tunneling"-->
<!--                                  />-->
<!--                                </div>-->
<!--                              </div>-->
                              <!--                              Proto fields for EoGRE -->
<!--                              <div-->
<!--                                class="form-group mt-1 mb-1"-->
<!--                                v-if="newWlanData.tunneling  && newWlanData.hasOwnProperty('proto')"-->
<!--                              >-->
<!--                                <label for="proto" class="">{{ $t('wlans.proto') }}</label>-->
<!--                                <select-->
<!--                                   :disabled="true"-->
<!--                                  @change="changeProtoInSelector"-->
<!--                                  class="form-control select-disabled"-->
<!--                                  name="proto"-->
<!--                                  v-model="newWlanData.proto"-->
<!--                                  id="proto"-->
<!--                                  :class="{-->
<!--                                    input: true,-->
<!--                                    'select-disabled': !newWlanData.tunneling-->
<!--                                  }"-->
<!--                                >-->
<!--                                  &lt;!&ndash;                                  <option value="" disabled selected></option>&ndash;&gt;-->
<!--                                  <option :value="proto.value" :key="proto.value"-->
<!--                                          v-for="proto in typesOfProto">-->
<!--                                    {{ proto.name }}-->
<!--                                  </option>-->
<!--                                </select>-->
<!--                              </div>-->
                              <div
                                class="form-group mb-2 mt-h"
                                v-if="
                                  hostsList[0] &&
                                  interfacesWithDetailsFromAllHostsAsArray.length &&
                                  newWlanData.tunneling &&
                                  newWlanData.proto !== 'gretap' && showInterfaceSelectorInCentralSwitchingSettingsDependingOnBuild
                                "
                              >
                                <label for="default_tunnel" class="">{{ $t('wlans.interface')
                                  }}</label>

                                <Multiselect
                                  label="nameWithType"
                                  trackBy="name"
                                  v-model="newWlanDataDefaultTunnel"
                                  :multiple="false"
                                  :options="interfacesWithDetailsFromAllHostsAsArray ||  []"
                                  :closeOnSelect="true"
                                  :hideSelected="true"
                                  v-validate="'required|multiSelectWithInterfaceNotEmpty'"
                                  :data-vv-as="$t('wlans.interface')"
                                  id="default_tunnel"
                                  data-vv-name="Interface"
                                  :placeholder="$t('wlans.selectSingleInterface')"
                                  :selectLabel="$t('aps.wlansSelectorTip')"
                                  :disabled="!newWlanData.tunneling || isDisable"
                                  @input="selectWlanDefaultTunnel"
                                  :class="{ 'multiselect-disabled': !newWlanData.tunneling || isDisable}"
                                  :allowEmpty="true"
                                >
                                  <template v-slot:noOptions>
                                    {{ $t('general.noDataToDisplay') }}
                                  </template>
                                  <!--                                  <template v-slot:singleLabel>-->
                                  <!--                                    {{labelForSelectedInTunnelMiltiselect()}}-->
                                  <!--                                  </template>-->
                                  <template v-slot:noResult>
                                    {{ $t('general.noResultForSearch') }}
                                  </template>
                                </Multiselect>
                                <a v-if="!(!newWlanData.tunneling || isDisable)" href="#"
                                   class="ml-q "
                                   @click.prevent="clearDefaultTunnel">
                                  <span>{{ $t('general.clear') }}</span>
                                </a>

                                <span v-show="errors.has('Interface')" class="help is-danger">
                                  {{ errors.first('Interface') }}
                                </span>
                                <small v-show="newWlanData.tunneling" class="help is-danger">
                                  {{ $t('wlans.bridge') }}
                                </small>
                              </div>

                              <div
                                v-else-if="(!interfacesWithDetailsFromAllHostsAsArray || !interfacesWithDetailsFromAllHostsAsArray.length) && isRootUser && showInterfaceSelectorInCentralSwitchingSettingsDependingOnBuild">
                                {{ $t('wlans.noInterfaces') }}
                              </div>

                              <div class="form-group mt-1">
                                <label for="vlan">
                                  VLAN
                                  <span class="text-muted" v-if="!newWlanData.vlan">{{ $t('wlans.vlanNative') }}</span>
                                </label>
                                <input
                                  type="text"
                                  id="vlan"
                                  name="vlan"
                                  :class="{ input: true, 'is-danger': errors.has('vlan') }"
                                  v-validate="vlanFieldValidtaionRules"
                                  :placeholder="$t('wlans.enterVLANPlaceHolder')"
                                  class="form-control"
                                  v-model="newWlanData.vlan"
                                  :disabled="isDisable || isNatActive"
                                />
                                <span v-show="errors.has('vlan')" class="help is-danger">
                                  {{ errors.first('vlan') }}
                                </span>
                              </div>
                              <div class="form-group" v-if="showDynamicVLANInputField">
                                <!-- DynamicVLAN field -->
                                <WInput md :label="$t('wlans.DynamicVLAN')"
                                        value=""
                                        :disabled="isDisable || isNatActive"
                                        id="DynamicVLAN"
                                        v-validate="'required|dynamicVLANFieldValidator'"
                                        name="DynamicVLAN"
                                        :data-vv-as="$t('wlans.DynamicVLAN')"
                                        v-model="dynamicVlansString"
                                        :errorText="errors.first('DynamicVLAN')"
                                        :placeholder="$t('wlans.DynamicVLANPlaceholder')">
                                </WInput>
                                <!-- end DynamicVLAN field -->
                              </div>

                            </div>


                          </div>
                          </transition>

                          <div>
                            <!--                          tunneling-->
                            <Switch-component
                              v-model="dataTrafficManagementBlocksShow.tunneling"
                              :disabled="isDisable || dataTrafficManagementBlocksShow.tunneling"
                              :label="$t('wlans.tunnelingShort')"
                               @change="dataTrafficManagementSwitchersHandler"
                              id="tunneling-switcher"
                            />
                          </div>
                          <transition name="wsettings">
                          <div class="ml-1" v-if="dataTrafficManagementBlocksShow.tunneling">
                            <div v-if="!newWlanData.nat">
<!--                              <div class="row " v-if="">-->
<!--                                <div class="form-group col-sm-6 mb-0">-->
<!--                                  <Switch-component-->
<!--                                    v-model="newWlanData.tunneling"-->
<!--                                    :disabled="isDisable || isNatActive"-->
<!--                                    :label="$t('wlans.centralized')"-->
<!--                                    @change="changeTunnelingSelector"-->
<!--                                    @input="$validator.reset()"-->
<!--                                    id="tunneling"-->
<!--                                  />-->
<!--                                </div>-->
<!--                              </div>-->
                              <!--                              Proto fields for EoGRE -->
<!--                              <div-->
<!--                                class="form-group mt-1 mb-1"-->
<!--                                v-if="newWlanData.tunneling  && newWlanData.hasOwnProperty('proto')"-->
<!--                              >-->
<!--                                <label for="proto" class="">{{ $t('wlans.proto') }}</label>-->
<!--                                <select-->
<!--                                  :disabled="true"-->
<!--                                  @change="changeProtoInSelector"-->
<!--                                  class="form-control select-disabled"-->
<!--                                  name="proto"-->
<!--                                  v-model="newWlanData.proto"-->
<!--                                  id="proto"-->
<!--                                  :class="{-->
<!--                                    input: true,-->
<!--                                    'select-disabled': !newWlanData.tunneling-->
<!--                                  }"-->
<!--                                >-->
<!--                                  &lt;!&ndash;                                  <option value="" disabled selected></option>&ndash;&gt;-->
<!--                                  <option :value="proto.value" :key="proto.value"-->
<!--                                          v-for="proto in typesOfProto">-->
<!--                                    {{ proto.name }}-->
<!--                                  </option>-->
<!--                                </select>-->
<!--                              </div>-->

<!--                              для будущего выбора протоколов при туннелировании-->
                              <div
                                class="form-group mt-1 mb-1"
                                v-if="newWlanData.tunneling  && newWlanData.hasOwnProperty('proto')"
                              >
                                <label for="proto" class="">{{ $t('wlans.proto') }}</label>
                                <select
                                  :disabled="isDisable"
                                  @change="changeProtoInSelectorInTunnelingBlock"
                                  class="form-control"
                                  name="proto"
                                  v-model="newWlanData.proto"
                                  id="proto"
                                  :class="{
                                    input: true,
                                    'select-disabled': !newWlanData.tunneling || isDisable
                                  }"
                                >
                                  <!--                                  <option value="" disabled selected></option>-->
                                  <option :value="proto.value" :key="proto.value"
                                          v-for="proto in typesOfProtoForTunnelingBlock">
                                    {{ proto.name }}
                                  </option>
                                </select>
                              </div>
                              <div
                                class="form-group mt-h"
                                v-if="newWlanData.hasOwnProperty('peer_address') && newWlanData.proto === 'gretap' && newWlanData.tunneling"
                              >
                                <label for="peerAddress">
                                  {{ $t('wlans.peerAddress') }}
                                </label>
                                <input
                                  key="peerAddress"
                                  type="text"
                                  :data-vv-as="$t('wlans.peerAddress')"
                                  id="peerAddress"
                                  name="peerAddress"
                                  :class="{ input: true, 'is-danger': errors.has('peerAddress') }"
                                  v-validate="'required|addressForEoGREServer'"
                                  :placeholder="$t('wlans.peerAddressPlaceholder')"
                                  data-vv-validate-on="change"
                                  class="form-control"
                                  v-model="newWlanData.peer_address"
                                  :disabled="isDisable || isNatActive"
                                />
                                <span v-show="errors.has('peerAddress')" class="help is-danger">
                                  {{ errors.first('peerAddress') }}
                                </span>
                              </div>


<!--                              <div class="form-group mt-1">-->
<!--                                <label for="vlan">-->
<!--                                  VLAN-->
<!--                                  <span class="text-muted" v-if="!newWlanData.vlan">{{ $t('wlans.vlanNative') }}</span>-->
<!--                                </label>-->
<!--                                <input-->
<!--                                  type="text"-->
<!--                                  id="vlan"-->
<!--                                  name="vlan"-->
<!--                                  :class="{ input: true, 'is-danger': errors.has('vlan') }"-->
<!--                                  v-validate="'numeric|between:0,4094'"-->
<!--                                  :placeholder="$t('wlans.enterVLANPlaceHolder')"-->
<!--                                  class="form-control"-->
<!--                                  v-model="newWlanData.vlan"-->
<!--                                  :disabled="isDisable || isNatActive"-->
<!--                                />-->
<!--                                <span v-show="errors.has('vlan')" class="help is-danger">-->
<!--                                  {{ errors.first('vlan') }}-->
<!--                                </span>-->
<!--                              </div>-->

                              <div class="form-group mt-1">
                                <label for="vlan">
                                  VLAN
                                  <span class="text-muted" v-if="!newWlanData.vlan">{{ $t('wlans.vlanNative') }}</span>
                                </label>
                                <input
                                  type="text"
                                  id="vlan"
                                  name="vlan"
                                  :class="{ input: true, 'is-danger': errors.has('vlan') }"
                                  v-validate="vlanFieldValidtaionRules"
                                  :placeholder="$t('wlans.enterVLANPlaceHolder')"
                                  class="form-control"
                                  v-model="newWlanData.vlan"
                                  :disabled="isDisable || isNatActive"
                                />
                                <span v-show="errors.has('vlan')" class="help is-danger">
                                  {{ errors.first('vlan') }}
                                </span>
                              </div>


                            </div>


                          </div>
                          </transition>
                        </div>
                        </transition>
              </div>

              <div v-if="showDataTrafficManagementTabRightColumn" class="w-50">
                <!-- ProxyARP switcher -->
                <div
                  v-if="showProxyARPSwitcher">
                  <div>
                    <Switch-component
                      v-model="newWlanData.proxy_arp"
                      :disabled="isDisable"
                      :label="$t('wlans.proxyARP')"
                      id="proxyARP"
                    />
                    <info class="ml-h" :content="$t('wlans.proxyARPHint')"/>
                  </div>
                </div>
                <!-- end ProxyARP switcher -->
              </div>
            </div>




            <!--            <button type="button" class="btn btn-outline-success float-right" @click="setNewWlanData">-->
            <!--              {{ $t('general.next') }}-->
            <!--            </button>-->
            <!--            <button type="button" class="btn btn-outline-secondary float-left" @click="this.$parent.prevStep">-->
            <!--              {{ $t('general.back') }}-->
            <!--            </button>-->
          </form>
        </div>
<!--        <div class="buttons-block-warpper">-->
<!--          <button type="button" class="btn btn-outline-success float-right" @click="setNewWlanData">-->
<!--            {{ $t('general.next') }}-->
<!--          </button>-->
<!--          &lt;!&ndash;          <button type="button" class="btn btn-outline-info float-right mr-h"&ndash;&gt;-->
<!--          &lt;!&ndash;                  @click="lastStepButtonClickHandler">&ndash;&gt;-->
<!--          &lt;!&ndash;            {{ $t('wlans.wizardSkipButton') }} <info :content="$t('wlans.wizardSkipButtonInfo')"/>&ndash;&gt;-->
<!--          &lt;!&ndash;          </button>&ndash;&gt;-->
<!--          <button type="button" class="btn btn-outline-secondary float-left" @click="this.$parent.prevStep">-->
<!--            {{ $t('general.back') }}-->
<!--          </button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import SwitchComponent from '../../../../components/Universal/Switch-component.vue';
import wlanService from '../../../../services/wlanService';
import Info from '../../../../components/Universal/info-icon.vue';
import Multiselect from 'vue-multiselect';
import helpers from "../../../../helpers";
import { VLANHelpers } from '@/helpers/VLAN';

import { wlanSettingsVisibilityMixin } from '../../mixins';

const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');

export default {
  name: 'DataTrafficManagementTab',
  mixins: [wlanSettingsVisibilityMixin],
  components: {
    SwitchComponent,
    Info,
    Multiselect
  },
  data() {
    return {
      dynamicVlansString: '',
      dataTrafficManagementBlocksShow: {
        localSwitching: false,
        centralSwitching: false,
        tunneling: false
      },
      typesOfProto: [
        {
          name: 'l2tpv3',
          value: 'l2tpv3'
        },
        {
          name: 'gretap',
          value: 'gretap'
        }
      ],
      typesOfProtoForTunnelingBlock: [
        {
          name: 'gretap',
          value: 'gretap'
        }
      ],
      newWlanDataDefaultTunnel: '',
      isNatActive: false,
      newWlanData: {
        // default_tunnel: '',
        // nat: false,
        vlan: '',
        radius: ''
        // tunneling: false,
        // firewall: {
        //   nat_access: false,
        // },
      }
    };
  },
  computed: {
    ...featureFlagsMapGetters(['isCiscoModeEnabled']),
    /**
     * Услиовие для отображения (или же нет) поля для ввода  Dynamic VLAN .
     * "Параметр должен быть доступен для настройки, только в том случае, если ранее пользователь включил параметр AAA Override.
     * В противном случае, параметр должен быть скрыт"
     * @returns {boolean}
     */
    showDynamicVLANInputField() {
      return this.newWlanData.aaa_override && this.showAAAOverrideSwitcher;
    },
    /**
     * Услиовие для отображения (или же нет) свитчера AAAOverride.
     * "Параметр должен быть доступен для настройки, только в том случае, если ранее на вкладке Безопасность → Слой 2 пользователь
     * заполнил поле Radius или MAC Radius авторизация. В противном случае, параметр должен быть скрыт"
     * @returns {boolean}
     */
    showAAAOverrideSwitcher() {
      if ((Array.isArray(this.newWlanData.guest_control.mac_radius_auth_servers) && this.newWlanData.guest_control.mac_radius_auth_servers.length) ||
        (Array.isArray(this.newWlanData.security.data.radiusauthentication) && this.newWlanData.security.data.radiusauthentication.length)) {
        return true;
      }
      return false;
    },
    /**
     * Правила для валидации VLAN поля для ввода
     * Зависит от сборки (WLC или нет) и включения или нет опции aaa_override
     * @returns {Object}
     */
    vlanFieldValidtaionRules() {
      if (this.isWLCProduct && this.newWlanData.aaa_override) {
        return {
          required: true,
          numeric: true,
          between: [VLANHelpers.getVLANBoundariesForWLCWithAAAOverrideOn().min, VLANHelpers.getVLANBoundariesForWLCWithAAAOverrideOn().max]
        };
      }
      return {
        numeric: true,
        between: [VLANHelpers.getVLANBoundaries().min, VLANHelpers.getVLANBoundaries().max]
      };
    },
    isDisable (){
      return false
    },
    interfacesWithDetailsFromAllHostsAsArray() {
      return Object.values(this.createInterfacesWithDetailsFromAllHostsList(this.hostsList));
    },
    interfacesWithDetailsFromAllHostsAsObjectWithIdKeys() {
      return this.createInterfacesWithDetailsFromAllHostsList(this.hostsList);
    },
    iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess() {
      return this.$store.state.wlanWizardGoingToLastStepAfterClickSkipButtonInProgess;
    },
    isRootUser() {
      return this.$store.state.baseLocation === this.$store.state.userData.base_location;
    },
    window() {
      return window;
    },
    hostsList: {
      get() {
        return this.$store.state.hostsList;
      },
      set() {}
    }
  },
  methods: {
    vlanForLocalSwitchingInputHandler() {
      if (this.errors.has('vlan')) {
        this.errors.remove('vlan');
      }
    },
    changeProtoInSelectorInTunnelingBlock() {

    },
    changeTunnelingSelector(){
      this.newWlanData.default_tunnel = '';
      // this.updatedWlanDefaultTunnel = {};
      this.$set(this, 'newWlanDataDefaultTunnel', '');
    },
    // setnewWlanDataDataTrafficManagementType(wlanObject) {
    //   // console.log(wlanObject)
    //   this.dataTrafficManagementBlocksShow.localSwitching = false;
    //   this.dataTrafficManagementBlocksShow.centralSwitching = false;
    //   this.dataTrafficManagementBlocksShow.tunneling = false;
    //   const type = this.getWlanDataTrafficManagementTypeFromWlanObject(wlanObject);
    //   if (type) {
    //     if (type === 'localSwitching') {
    //       this.dataTrafficManagementBlocksShow.localSwitching = true;
    //     }
    //     if (type === 'centralSwitching') {
    //       this.dataTrafficManagementBlocksShow.centralSwitching = true;
    //     }
    //     if (type === 'tunneling') {
    //       this.dataTrafficManagementBlocksShow.tunneling = true;
    //     }
    //   }
    //
    // },

    setnewWlanDataDataTrafficManagementType(wlanObject) {
      // console.log(wlanObject)
      this.dataTrafficManagementBlocksShow.localSwitching = false;
      this.dataTrafficManagementBlocksShow.centralSwitching = false;
      this.dataTrafficManagementBlocksShow.tunneling = false;
      const type = this.getWlanDataTrafficManagementTypeFromWlanObject(wlanObject);
      if (type) {
        if (type === 'localSwitching') {
          if (wlanObject.nat) {
            this.isNatActive = true;
          } else {
            this.isNatActive = false;
          }
          this.dataTrafficManagementBlocksShow.localSwitching = true;
        }
        if (type === 'centralSwitching') {
          this.dataTrafficManagementBlocksShow.centralSwitching = true;
        }
        if (type === 'tunneling') {
          this.dataTrafficManagementBlocksShow.tunneling = true;
        }
      }

    },

    // getWlanDataTrafficManagementTypeFromWlanObject(wlanObject) {
    //   if (wlanObject) {
    //     if (wlanObject.nat || (wlanObject.vlan  && !wlanObject.tunneling)) {
    //       return 'localSwitching'
    //     }
    //     if (wlanObject.tunneling && wlanObject.proto === 'l2tpv3') {
    //       return 'centralSwitching'
    //     }
    //     if (wlanObject.tunneling && wlanObject.proto !== 'l2tpv3') {
    //       return 'tunneling'
    //     }
    //   }
    //   return undefined
    // },
    getWlanDataTrafficManagementTypeFromWlanObject(wlanObject) {
      if (wlanObject) {
        if (!wlanObject.tunneling) {
          return 'localSwitching'
        }
        if (wlanObject.tunneling && wlanObject.proto === 'l2tpv3') {
          return 'centralSwitching'
        }
        if (wlanObject.tunneling && wlanObject.proto !== 'l2tpv3') {
          return 'tunneling'
        }
      }
      return undefined
    },
    dataTrafficManagementSwitchersHandler(event) {
      // this.newWlanData.vlan = '';
      this.newWlanData.vlan = 0;
      this.newWlanData.peer_address = ''
      this.checkNat();
      this.changeTunnelingSelector();
      // console.log(event);
      const eventValue = event.target.value;
      // console.log(eventValue)
      const switcherId = event.target.id;
      // console.log(switcherId);

      if (eventValue === 'false') {

        if (switcherId === 'localSwitching-switcher') {
          this.newWlanData.nat = false
          this.dataTrafficManagementBlocksShow.localSwitching = false;
          this.newWlanData.proto = 'l2tpv3'
          // console.log('here')
        }
        if (switcherId === 'centralSwitching-switcher') {
          this.newWlanData.tunneling = false;
          this.newWlanData.proto = 'l2tpv3'
          this.dataTrafficManagementBlocksShow.centralSwitching = false;

        }
        if (switcherId === 'tunneling-switcher') {
          this.newWlanData.tunneling = false;
          this.newWlanData.proto = 'l2tpv3'
          this.dataTrafficManagementBlocksShow.centralSwitching = false;
        }
      } else {
        if (switcherId === 'localSwitching-switcher') {
          this.newWlanData.tunneling = false;
          this.newWlanData.proto = 'l2tpv3'
          this.dataTrafficManagementBlocksShow.centralSwitching = false;
          this.dataTrafficManagementBlocksShow.tunneling = false;
          // console.log('here')
        }
        if (switcherId === 'centralSwitching-switcher') {
          this.newWlanData.tunneling = true;
          this.newWlanData.proto = 'l2tpv3'
          this.newWlanData.nat = false
          this.dataTrafficManagementBlocksShow.localSwitching = false;
          this.dataTrafficManagementBlocksShow.tunneling = false;

        }
        if (switcherId === 'tunneling-switcher') {
          this.newWlanData.nat = false;
          this.newWlanData.tunneling = true;
          this.newWlanData.proto = 'gretap'
          this.dataTrafficManagementBlocksShow.localSwitching = false;
          this.dataTrafficManagementBlocksShow.centralSwitching = false;
        }
      }

    },
    customLabelForTunnelMiltiselect(tunnelName) {
      try {
        return this.createTunnelCaptionByName(tunnelName)
      } catch (e) {
        return tunnelName;
      }
    },
    selectWlanDefaultTunnel(payload) {
      // console.log('payload', payload)
      // this.newWlanData.default_tunnel = payload.name;
      this.$set(this.newWlanData, 'default_tunnel', payload.name);
    },
    labelForSelectedInTunnelMiltiselect() {
      const tunnelName = this.newWlanData.default_tunnel
      return this.createTunnelCaptionByName(tunnelName);
    },
    createTunnelCaptionByName(tunnelName) {
      try {
        return `${tunnelName} (${this.$t('general.type').toLowerCase()} ${this.interfacesWithDetailsFromAllHostsAsObjectWithIdKeys[tunnelName].type})`
      } catch (e) {
        return tunnelName
      }
    },
    clearDefaultTunnel() {
      this.newWlanData.default_tunnel = '';
      // this.newWlanDataDefaultTunnel = ''
      this.$set(this, 'newWlanDataDefaultTunnel', '');
      this.$store.commit('setNewWlanDataDefaultTunnelClear');
    },
    // createInterfacesWithDetailsFromAllHostsList(hosts) {
    //   return helpers.createInterfacesWithDetailsFromAllHostsList(hosts)
    //   // try {
    //   //   const result = {}
    //   //   for (const host of hosts) {
    //   //     if (Object.hasOwnProperty.call(host, 'interfaces_details') &&
    //   //       Array.isArray(host.interfaces_details) &&
    //   //       host.interfaces_details.length > 0) {
    //   //       for (const interfaceWithDetails of host.interfaces_details) {
    //   //         if (Object.prototype.hasOwnProperty.call(interfaceWithDetails, 'name') &&
    //   //           interfaceWithDetails.name !== '' &&
    //   //           !Object.prototype.hasOwnProperty.call(result, interfaceWithDetails.name)) {
    //   //           result[interfaceWithDetails.name] = interfaceWithDetails;
    //   //         }
    //   //       }
    //   //     }
    //   //   }
    //   //   return result;
    //   // } catch (e) {
    //   //   console.log(e)
    //   //   return {}
    //   // }
    // },
    createInterfacesWithDetailsFromAllHostsList(hosts) {
      return helpers.createInterfacesWithDetailsAndNameWithTypeFromAllHostsList(this, hosts)
      // try {
      //   const result = {}
      //   for (const host of hosts) {
      //     if (Object.hasOwnProperty.call(host, 'interfaces_details') &&
      //       Array.isArray(host.interfaces_details) &&
      //       host.interfaces_details.length > 0) {
      //       for (const interfaceWithDetails of host.interfaces_details) {
      //         if (Object.prototype.hasOwnProperty.call(interfaceWithDetails, 'name') &&
      //           interfaceWithDetails.name !== '' &&
      //           !Object.prototype.hasOwnProperty.call(result, interfaceWithDetails.name)) {
      //           result[interfaceWithDetails.name] = interfaceWithDetails;
      //         }
      //       }
      //     }
      //   }
      //   return result;
      // } catch (e) {
      //   console.log(e)
      //   return {}
      // }
    },
    lastStepButtonClickHandler(event) {
      this.setNewWlanData(event, true);
    },
    changeProtoInSelector() {
      // если выбриаем proto = gretap то сбрасываем тунель
      if (this.newWlanData.hasOwnProperty('proto') && this.newWlanData.proto === 'gretap') {
        // console.log('clear tunnel')
        this.newWlanData.default_tunnel = '';
        // this.newWlanDataDefaultTunnel = '';
        this.$set(this, 'newWlanDataDefaultTunnel', '');
        this.$store.commit('setNewWlanDataDefaultTunnelClear');
      }
    },

    checkNat() {
      if (!this.newWlanData) {
        return;
      }

      if (this.newWlanData.nat) {
        this.newWlanData.tunneling = false;
        this.newWlanData.vlan = '';
        this.isNatActive = true;
      } else {
        this.newWlanData.vlan = 0;
        this.newWlanData.firewall.nat_access = false;
        this.isNatActive = false;
      }
    },
    // setNewWlanData(event, toLastStepClicked = false) {
    //   if (this.newWlanData.vlan) {
    //     this.$validator.validateAll().then(
    //       (result) => {
    //         if (result) {
    //           if (this.newWlanData.hidden === 'true') {
    //             this.newWlanData.hidden = true;
    //           }
    //           if (this.newWlanData.hidden === 'false') {
    //             this.newWlanData.hidden = false;
    //           }
    //           this.$store.commit('setNewWlanData', this.newWlanData);
    //           // this.$parent.nextStep();
    //           if (toLastStepClicked === false) {
    //             this.$parent.nextStep();
    //           } else {
    //             // this.$parent.nextStep(true);
    //             this.$parent.lastStep();
    //           }
    //         }
    //       },
    //       () => {
    //         console.warn('Validation failed');
    //       }
    //     );
    //   } else {
    //     this.$validator.validateAll().then(
    //       (result) => {
    //         if (result) {
    //           if (this.newWlanData.hidden === 'true') {
    //             this.newWlanData.hidden = true;
    //           }
    //           if (this.newWlanData.hidden === 'false') {
    //             this.newWlanData.hidden = false;
    //           }
    //           this.$store.commit('setNewWlanData', this.newWlanData);
    //           // this.$parent.nextStep();
    //           if (toLastStepClicked === false) {
    //             this.$parent.nextStep();
    //           } else {
    //             // this.$parent.nextStep(true);
    //             this.$parent.lastStep();
    //           }
    //         }
    //       },
    //       () => {
    //         console.warn('Validation failed');
    //       }
    //     );
    //   }
    // }
    validateAndSetNewWlanData(currentTab, nextTab){
      this.setNewWlanData(currentTab, nextTab)
    },
    setNewWlanData(currentTab, nextTab) {
      this.newWlanData.vlans = VLANHelpers.convertDynamicVlanStringToArrayFormat(this.dynamicVlansString)
      if (this.newWlanData.vlan) {
        this.$validator.validateAll().then(
          (result) => {
            if (result) {
              if (this.newWlanData.hidden === 'true') {
                this.newWlanData.hidden = true;
              }
              if (this.newWlanData.hidden === 'false') {
                this.newWlanData.hidden = false;
              }
              this.$store.commit('setNewWlanData', this.newWlanData);
              // this.$parent.nextStep();
              this.$emit('dataIsValid', { nextTab: nextTab, currentTab:  currentTab})
            } else {
              this.$emit('dataNoValid', { nextTab: nextTab, currentTab:  currentTab })
            }
          },
          () => {
            this.$emit('dataNoValid', { nextTab: nextTab, currentTab:  currentTab })
            console.warn('Validation failed');
          }
        );
      } else {
        this.$validator.validateAll().then(
          (result) => {
            if (result) {
              if (this.newWlanData.hidden === 'true') {
                this.newWlanData.hidden = true;
              }
              if (this.newWlanData.hidden === 'false') {
                this.newWlanData.hidden = false;
              }
              this.$store.commit('setNewWlanData', this.newWlanData);
              // this.$parent.nextStep();
              this.$emit('dataIsValid', { nextTab: nextTab, currentTab:  currentTab })
            } else {
              this.$emit('dataNoValid', { nextTab: nextTab, currentTab:  currentTab })
            }
          },
          () => {
            this.$emit('dataNoValid', { nextTab: nextTab, currentTab:  currentTab })
            console.warn('Validation failed');
          }
        );
      }
    }
  },
  created() {
    wlanService.getHosts(this);
    this.newWlanData = JSON.parse(JSON.stringify(this.$store.state.newWlanData));
    const currentDefaultTunnel = this.newWlanData.default_tunnel;
    // console.log('currentDefaultTunnel', currentDefaultTunnel)
    // this.newWlanDataDefaultTunnel = '';
    this.$set(this, 'newWlanDataDefaultTunnel', '');
    // this.$store.commit('setNewWlanDataDefaultTunnelClear');
    if (currentDefaultTunnel && currentDefaultTunnel !== '') {
      const currentDefaultTunnelAsObject = this.interfacesWithDetailsFromAllHostsAsObjectWithIdKeys[currentDefaultTunnel];
      if (currentDefaultTunnelAsObject) {
        this.newWlanDataDefaultTunnel = JSON.parse(JSON.stringify(currentDefaultTunnelAsObject));
      } else {
        this.newWlanDataDefaultTunnel = {
          name: this.newWlanData.default_tunnel,
          nameWithType: this.newWlanData.default_tunnel
        }
      }
    }
    this.setnewWlanDataDataTrafficManagementType(this.newWlanData);

    if (this.newWlanData && this.newWlanData.vlans && Array.isArray(this.newWlanData.vlans)) {
      this.dynamicVlansString = VLANHelpers.convertDynamicVlanArrayToStringFormat(this.newWlanData.vlans);
    }


    // проверяем не пришли ли мы сюда после нажатия кнопки Пропустить
    if (
      Object.prototype.hasOwnProperty.call(this.$route, 'params') &&
      Object.prototype.hasOwnProperty.call(this.$route.params, 'lastStepClicked') &&
      this.$route.params.lastStepClicked === true
    ) {
      this.lastStepButtonClickHandler();
    }
  }
};
</script>
<style scoped>
.buttons-block-warpper {
  position: absolute;
  bottom: 1.25rem;
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.skip-button-in-wlan-wizard {
  font-size: 0.75rem;
}
</style>
