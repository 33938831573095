<template>
  <div class="w-table-wrapper" :class="[disabledClass]">
    <div class="w-table-menu">
      <div v-if="withTableMenu">
        <slot name="table-menu">
        </slot>
      </div>
      <div class="w-table-menu__rows-controller">
        <button v-if="isGroupEnabled"
          class="w-table-menu__expand-collapse-rows-button"
          @click="isGroupExpanded ? collapseAllRows() : expandAllRows()"
        >
          <i class="fa fa-2x icon-cols"
            :class="[isGroupExpanded ? 'fa-compress' : 'fa-expand']"
          />
        </button>
        <ColumnVisibilityDropdown v-if="withToggleColumnVisibilityMode"
          :columns="columnsData"
          @on-change-column-visibility="toggleColumnVisibility"
        />
      </div>
    </div>
    <vue-good-table
      v-bind="$attrs"
      ref="goodTable"
      :styleClass="borderedClass"
      :columns="columnsData"
      :rows="rows"
      @on-row-click="$emit('on-row-click', $event)"
      @on-row-dblclick="$emit('on-row-dblclick', $event)"
      @on-cell-click="$emit('on-cell-click', $event)"

      @on-row-mouseenter="$emit('on-row-mouseenter', $event)"
      @on-row-mouseleave="$emit('on-row-mouseleave', $event)"

      @on-search="$emit('on-search', $event)"
      @on-column-filter="$emit('on-column-filter', $event)"

      @on-sort-change="onSortChange"

      @on-select-all="onSelectedAll"
      @on-selected-rows-change="onSelectedRowsChange"

      @on-page-change="$emit('on-page-change', $event)"
      @on-per-page-change="$emit('on-per-page-change', $event)"
    >
      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>

      <template v-slot:emptystate>
        <div class="table-empty-text">
          {{ $t('general.noDataToDisplay') }}
        </div>
      </template>

      <!-- <template v-slot:loadingContent>
      </template> -->

      <!--
        кастомный режим работы пагинации. Использовать его стоит тогда, когда вся таблица работает в regular mode (сортировка и фильтрация на фронте
        https://xaksis.github.io/vue-good-table/guide/configuration/#mode),
        а пагинацию хотим в remote mode (пагинация через бэк).
        Чтобы активировать, достаточно в pagination-options WTable добавить свойство remoteMode: true
      -->
      <template slot="pagination-bottom" slot-scope="props"
        v-if="isPaginationOfflineModeEnabled"
      >
        <WTablePagination
          ref="customPagination"
          :total="props.total"
          v-bind="paginationRemoteModeProps"
          @page-changed="(payload) => {
            props.pageChanged({ currentPage: payload.currentPage })
          }"
          @per-page-changed="(payload) => {
            props.perPageChanged({currentPerPage: payload.currentPerPage })
          }">
        </WTablePagination>
      </template>

    </vue-good-table>
  </div>
</template>

<script>
/**
 * UI-компонент таблицы.
 * Используется во всем проекте. Регистрируется глобально (в конкретном компоненте регистрировать не нужно).
 * ! info:
 * Компонент таблицы основан на библиотеке vue-good-table и использует ее под капотом и проксирует ее интерфейс.
 * Поэтому все ее пропсы и евенты можно использовать при работе с WTable.
 * Подробнее о всех возможностях в доке:
 * {@link https://xaksis.github.io/vue-good-table/}.
 *
 * ! отличия от vue-good-table:
 * WTable немного адаптирован под наши нужды:
 * 1. добавлен отдельный remoteMode для пагинации, чтобы она была более независимой в ситуациях, когда нам нужен смешанный режим работы
 * (например, пагинация управляется бэком, а фильтрация и сортировка на фронте. Подробнее о режимах работы vue-good-table:
 * {@link https://xaksis.github.io/vue-good-table/guide/configuration/#mode}).
 * 2. добавлен slot "table-menu", который позволяет добавить в шапку таблицы внешние элементы (например, кнопки).
 * 3. добавлен ColumnVisibilityDropdown в шапку таблицы для возможности скрытия/показа столбцов.
 * 4. переработаны стили таблицы и адаптированы под наши нужды (styles.css). Из-за этого дефолтный функционал vue-good-table с темами не будет работать
 * {@link https://xaksis.github.io/vue-good-table/guide/style-configuration/#default}.
 * 5. добавлено событие "update:columns". О нем см. ниже.
 * 6. Добавлена кнопка expand/collapse для таблиц с группировкой
 * {@link https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#collapsable-rows}
 * 7. добавлена возможность контролровать стейт сортировки извне через проп.
 * Важно, чтобы sortState был массивом.
 * :sort-options="{
      enabled: true,
      sortState: sortState
    }"
 *
 * @typedef {Object} WTable
 * @property {Array} columns - Массив столбцов с настройками.
 * {@link https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label}.
 *
 * @property {Array} rows - Массив строк с настройками.
 * {@link https://xaksis.github.io/vue-good-table/guide/configuration/#rows}.
 * {@link https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row}.
 *
 * @property {Boolean} [isDisabled=false] - Флаг, указывающий, можно ли взаимодействовать с таблицей.
 * @property {Boolean} [bordered=false] - Флаг, указывающий, имеют ли таблица вертикальные границы.
 * @property {} NATIVE_props - см. в доке: {@link https://xaksis.github.io/vue-good-table/guide/configuration/}.
 *
 * События:
 * @event update:columns - Событие, указывающее, что колонки обновились.
 * Использовать, например, когда какую-то колонку скрыли/раскрыли - нужно обновить columns.
 * Пример, как работать с update:columns событием можно найти в компоненте LogFiltersTable.vue.
 * @event NATIVE_events - см. в доке: {@link https://xaksis.github.io/vue-good-table/guide/configuration/table-events.html}.
 *
 * Слоты:
 * @slot table-menu - позволяет добавить в шапку таблицы внешние элементы (например, кнопки).
 * @slot NATIVE_slots - см. в доке {@link https://xaksis.github.io/vue-good-table/guide/advanced/#custom-row-template}.
 *
 * Примеры использования компонента WTable:
 *
 *  @example
 * Пример самого базового использования:
 *  <template>
 *    <WTable :columns="columns" :rows="rows">
 *      <template v-slot:table-menu>
 *        <!-- Custom table menu content -->
 *      </template>
 *    </WTable>
 *  </template>
 *  <script>
 *  export default {
 *    data() {
 *      return {
 *        columns: [...], // Define columns array here
 *        rows: [...], // Define rows array here
 *      };
 *    }
 *  };
 *
 * @example
 * Пример реального использования:
 * см. компонент - LogFiltersTable.vue
 *
 * @example
 * Больше примеров на каждый случай можно найти в доке:
 * {@link https://xaksis.github.io/vue-good-table/}.
 *
*/

import { VueGoodTable } from 'vue-good-table';

import {
  WTablePagination,
  ColumnVisibilityDropdown
} from './components';

export default {
  name: 'WTable',
  components: {
    VueGoodTable,
    WTablePagination,
    ColumnVisibilityDropdown
  },
  props: {
    columns: {
      type: Array,
      required: true
    },
    rows: {
      type: Array,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    withToggleColumnVisibilityMode: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // стейт, который отвечает за состояние - раскрыты ли сгруппированные строки или нет.
      // Пришлось создать стейт, тк в vue-good-table нет events для этого.
      // подробнее: https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#collapsable-rows
      // Работает только при isGroupEnabled === true
      isGroupExpanded: false
    };
  },
  watch: {
    rows() {
      // сбрасываем таблицу к изначальному состоянию, когда кол-во строк меняется
      // например, добавили/удалили что-то
      // ! warning: но в remote mode это делать не нужно. Иначе пагинация будет баговать и не синхронизироваться с текущей страницей таблицы
      const isRemoteMode = this.$attrs.mode === 'remote';
      if (isRemoteMode) {
        return;
      }

      this.resetTable();
    },
    sortState(newVal) {
      // если хотим контролировать стейт сортировки, то через пропс sort-options -> sortState
      // приходится лезть по рефам и менять состояние.
      // В библиотеке нет возможности нативно задать извне контролируемый стейт
      if (this.$refs?.goodTable?.sorts && this.$refs?.goodTable?.$refs['table-header-primary']) {
        this.$refs.goodTable.sorts = newVal;
        // this.$refs.goodTable.changeSort(newVal)

        this.$refs.goodTable.$refs['table-header-primary'].sorts = newVal;
      }
    }
  },
  computed: {
    columnsData: {
      get() {
        return this.columns;
      },
      set(value) {
        this.$emit('update:columns', value);
      }
    },
    isPaginationOfflineModeEnabled() {
      return Boolean(this.$attrs['pagination-options'].enabled && this.$attrs['pagination-options'].remoteMode);
    },
    paginationRemoteModeProps() {
      const {
        perPage,
        perPageDropdownEnabled,
        perPageDropdown,
        dropdownAllowAll,
        mode,
        jumpFirstOrLast,
        firstLabel,
        lastLabel,
        nextLabel,
        prevLabel,
        rowsPerPageLabel,
        ofLabel,
        pageLabel,
        allLabel,
        infoFn
      } = this.$attrs['pagination-options'];

      const offlineModePaginationProps = {
        perPage,
        rtl: this.$attrs.rtl,
        perPageDropdownEnabled,
        customRowsPerPageDropdown: perPageDropdown,
        paginateDropdownAllowAll: dropdownAllowAll,
        mode,
        jumpFirstOrLast,
        firstText: firstLabel,
        lastText: lastLabel,
        nextText: nextLabel,
        prevText: prevLabel,
        rowsPerPageText: rowsPerPageLabel,
        ofText: ofLabel,
        pageText: pageLabel,
        allText: allLabel,
        infoFn
      };

      return offlineModePaginationProps;
    },
    sortState() {
      return this.$attrs['sort-options']?.sortState;
    },
    withTableMenu() {
      return Boolean(this.$slots?.['table-menu']?.[0]);
    },
    isGroupEnabled() {
      return Boolean(this.$attrs['group-options']?.enabled);
    },
    disabledClass() {
      return this.isDisabled ? 'disabled' : '';
    },
    borderedClass() {
      return this.bordered ? 'vgt-table bordered' : 'vgt-table';
    }
  },
  methods: {
    expandAllRows() {
      if (!this.$refs?.goodTable) {
        throw new Error('there is an error with refs in WTable.vue');
      }

      this.isGroupExpanded = true;
      this.$refs.goodTable.expandAll();
    },
    collapseAllRows() {
      if (!this.$refs?.goodTable) {
        throw new Error('there is an error with refs in WTable.vue');
      }

      this.isGroupExpanded = false;
      this.$refs.goodTable.collapseAll();
    },
    toggleColumnVisibility(field) {
      this.columnsData = this.columnsData.map(column => {
        if (column.field === field) {
          return {
            ...column,
            hidden: !column.hidden
          };
        }

        return column;
      });
    },
    onSelectedAll(event) {
      const { selectedRows } = event;

      const onlyEnabledSelectedRows = selectedRows.filter(row => !row.vgtDisabled);

      if (onlyEnabledSelectedRows.length === 0) {
        return;
      }

      this.$emit('on-select-all', {
        selected: true,
        selectedRows: onlyEnabledSelectedRows
      });
    },
    onSelectedRowsChange(event) {
      const { selectedRows } = event;

      // фикс отфильтровываем те строки, которые были задизейблены
      // по повидению либы по событию selectAll дизейбленные строки все равно выбираются. Это баг либы
      const enabledToSelectionRows = this.$props.rows.filter(row => !row.vgtDisabled);
      const onlyEnabledSelectedRows = selectedRows.filter(row => !row.vgtDisabled);

      this.$emit('on-selected-rows-change', { selectedRows: onlyEnabledSelectedRows });

      if (enabledToSelectionRows.length === onlyEnabledSelectedRows.length) {
        this.$emit('on-select-all', {
          selected: true,
          selectedRows: onlyEnabledSelectedRows
        });
      }
    },
    onSortChange(params) {
      if (!this.$refs?.goodTable) {
        throw new Error('there is an error with refs in WTable.vue');
      }

      // https://xaksis.github.io/vue-good-table/guide/configuration/table-events.html#on-sort-change
      // https://github.com/xaksis/vue-good-table/issues/778
      const sortedTableRows = this.$refs.goodTable.processedRows[0].children;

      this.$emit('on-sort-change', { params, sortedTableRows });
    },
    resetTable() {
      this.$refs.goodTable.reset();

      if (this.isPaginationOfflineModeEnabled) {
        this.$refs.customPagination.reset();
      }
    }
  }
};
</script>

<style lang="css" scoped>
@import './styles.css';

.w-table-wrapper {
  padding: 0 4px;
  position: relative;
}

.w-table-wrapper.disabled {
  pointer-events: none;
  opacity: var(--opacity);
}

.w-table-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  padding: 8px 0;
}

.w-table-menu > div:first-of-type {
  flex: 1 1 100%;
}

.w-table-menu > div:last-of-type {
  flex: 0 1 auto;
  margin-left: auto;
}

.table-empty-text {
  text-align: center;
}

.w-table-menu__rows-controller {
  display: flex;
  align-items: center;
  gap: 16px;
}

.w-table-menu__expand-collapse-rows-button {
  width: 100%;
  position: relative;
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  color: inherit;
  transition: var(--transition);
}

.w-table-menu__expand-collapse-rows-button:hover {
  opacity: var(--opacity);
}
</style>
