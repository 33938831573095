import { render, staticRenderFns } from "./SidebarNewStructure.vue?vue&type=template&id=703bbf1b&scoped=true&"
import script from "./SidebarNewStructure.vue?vue&type=script&lang=js&"
export * from "./SidebarNewStructure.vue?vue&type=script&lang=js&"
import style0 from "./SidebarNewStructure.vue?vue&type=style&index=0&id=703bbf1b&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "703bbf1b",
  null
  
)

export default component.exports