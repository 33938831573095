<template>
  <date-picker
    @pick="clickToCalendar"
    v-model="time"
    :editable="editable"
    :range="range"
    :range-separator="rangeSeparator"
    :format="format"
    :value="value"
    :value-type="valueType"
    :lang="lang"
    :placeholder="placeholder"
    :type="type"
    :clearable="clearable"
    :confirm="confirm"
    :shortcuts="shortcutsButtons"
    :disabled="disabled"
    :append-to-body="appendToBody"
    :input-class="inputClass"
    :class="{ 'is-danger': showError }"
    :disabled-date="disabledDate"
    :disabled-time="disabledTime"
    @focus="$emit('focus')"
    @open="$emit('open')"
    @confirm="$emit('confirm')"
    @clear="$emit('clear')"
    @close="$emit('close')"
  >
    <template v-if="range" v-slot:footer="{ emit }">
      <div class="d-flex datepicker-custom__container">
        <select v-model="timeShortcut.timeZone" name="time-zone" class="form-control form-control-sm">
          <option value="last">{{ $t('drPicker.last') }}</option>
          <option value="next">{{ $t('drPicker.next') }}</option>
        </select>
        <input
          v-model="timeShortcut.amount"
          name="past-future-value"
          value="1"
          class="form-control form-control-sm mx-w80"
        />
        <select v-model="timeShortcut.metrics" name="past-future-metrics" class="form-control form-control-sm">
          <option value="seconds">{{ $t('drPicker.seconds') }}</option>
          <option value="minutes">{{ $t('drPicker.minutes') }}</option>
          <option value="hours">{{ $t('drPicker.hours') }}</option>
          <option value="days">{{ $t('drPicker.days') }}</option>
          <option value="weeks">{{ $t('drPicker.weeks') }}</option>
          <option value="months">{{ $t('drPicker.months') }}</option>
          <option value="years">{{ $t('drPicker.years') }}</option>
        </select>
        <WButton info outline
          customClass="mx-btn datepicker-custom-date-select-button"
          @click="handleCustomTimeRange(emit)"
          :disabled="isCustomTimeRangeButtonDisabled"
        >
          {{ $t('drPicker.apply') }}
        </WButton>
      </div>
    </template>
    <template v-if="range && enableMaxDateRangeLimit" v-slot:header>
      <div class="text-center">
        <h6 class="mt-1">
          <b>{{ $t('drPicker.maxDateRangeLimit') }}: {{ moment.duration(maxDateRangeLimit).humanize() }}</b>
        </h6>
      </div>
    </template>
    <template v-if="!showIcon" #icon-calendar><span /></template>
  </date-picker>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: localeMapGetters } = createNamespacedHelpers('locale');

// NOTE : check data with server (*1000 ???)
// NOTE: for report have to use report === true prop

export default {
  components: { DatePicker },
  // NOTE: all props are here: https://github.com/mengxiong10/vue2-datepicker
  props: {
    range: { type: Boolean, default: true },
    value: { type: [Object, Number, Date], required: true },
    editable: { type: Boolean, default: false },
    rangeSeparator: { type: String, default: ' - ' },
    format: { type: String, default: 'HH:mm, D MMM YYYY' },
    valueType: { type: String, default: 'timestamp' },
    type: { type: String, default: 'datetime' },
    placeholder: { type: String, default: '' },
    clearable: { type: Boolean, default: false },
    confirm: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    onChange: { type: Function, default: () => {} },
    report: { type: Boolean, default: false },
    appendToBody: { type: Boolean, default: true },
    inputClass: { type: String, default: 'mx-input' },
    showIcon: { type: Boolean, default: true },
    enableMaxDateRangeLimit: { type: Boolean, default: false },
    maxDateRangeLimit: { type: Number, default: 8121600000 }, // 3 месяца (31 день + 31 день + 31 день + 1 день (на всякий случай) день) (в милисекундах)_ по умолчанию,
    showError: { type: Boolean, default: false },
    disabledDate: { type: Function },
    disabledTime: { type: Function }
  },
  data() {
    return {
      timeShortcut: {
        timeZone: 'last',
        amount: '',
        metrics: 'days'
      },
      time: this.range ?
        [
          this.value.startDate || (this.report ? this.value.start : this.value.start * 1000), // in seconds
          this.value.endDate || (this.report ? this.value.stop : this.value.stop * 1000) // in seconds
        ] :
        this.value * 1000,
      lang: {},
      shortcutsButtons: []
    };
  },
  computed: {
    ...localeMapGetters(['locale']),
    isCustomTimeRangeButtonDisabled() {
      // если значения в секлекторах не изменялось (по умолчанию), тогда дизейблим кнопку
      if (
        this.timeShortcut.amount === '' &&
        this.timeShortcut.metrics === 'days' &&
        this.timeShortcut.timeZone === 'last'
      ) {
        return true;
      }
      if (this.timeShortcut.amount === '') {
        return true;
      }
      return false;
    }
  },
  watch: {
    value() {
      if (this.onChange !== undefined) {
        this.onChange();
      }
    },
    time() {
      if (this.range) {
        this.$emit(
          'input',
          this.value.start ?
            {
              start: this.report ? this.time[0] : moment(this.time[0]).unix(),
              stop: this.report ? this.time[1] : moment(this.time[1]).unix()
            } :
            { startDate: this.time[0], endDate: this.time[1] }
        );
        if (this.checkIsDateRangeLimitEnabledAndExceeded()) {
          this.$emit('isDateRangeLimitEnabledAndExceeded', {
            dateRangeLimitEnabledAndExceeded: true,
            maxDateRangeLimit: this.maxDateRangeLimit
          });
          // console.log('more then 3 month')
        } else {
          this.$emit('isDateRangeLimitEnabledAndExceeded', {
            dateRangeLimitEnabledAndExceeded: false,
            maxDateRangeLimit: this.maxDateRangeLimit
          });
          // console.log('not more 3 month')
        }
      } else this.$emit('input', this.report ? this.time : moment(this.time).unix());
    },
    locale() {
      this.initFieldsWithI18N();
    }
  },
  created() {
    this.initFieldsWithI18N();
  },
  methods: {
    /**
     * Используется для инициализции и переинициализации (при смене языка) полей в data компонента
     * которые (поля) задают локаль компонента
     */
    initFieldsWithI18N() {
      this.setLang();
      this.setShortcutsButtons();
    },
    /**
     * Используется для установки lang
     */
    setLang() {
      this.lang = {
        // the locale of formatting and parsing function
        formatLocale: {
          // MMMM
          months: [
            this.$i18n.t('drPicker.january_full'),
            this.$i18n.t('drPicker.february_full'),
            this.$i18n.t('drPicker.march_full'),
            this.$i18n.t('drPicker.april_full'),
            this.$i18n.t('drPicker.may_full'),
            this.$i18n.t('drPicker.june_full'),
            this.$i18n.t('drPicker.july_full'),
            this.$i18n.t('drPicker.august_full'),
            this.$i18n.t('drPicker.september_full'),
            this.$i18n.t('drPicker.october_full'),
            this.$i18n.t('drPicker.november_full'),
            this.$i18n.t('drPicker.december_full')
          ],
          // MMM
          monthsShort: [
            this.$i18n.t('drPicker.january_short'),
            this.$i18n.t('drPicker.february_short'),
            this.$i18n.t('drPicker.march_short'),
            this.$i18n.t('drPicker.april_short'),
            this.$i18n.t('drPicker.may_short'),
            this.$i18n.t('drPicker.june_short'),
            this.$i18n.t('drPicker.july_short'),
            this.$i18n.t('drPicker.august_short'),
            this.$i18n.t('drPicker.september_short'),
            this.$i18n.t('drPicker.october_short'),
            this.$i18n.t('drPicker.november_short'),
            this.$i18n.t('drPicker.december_short')
          ],
          // ddd
          weekdaysShort: [
            this.$i18n.t('drPicker.sunday_short'),
            this.$i18n.t('drPicker.monday_short'),
            this.$i18n.t('drPicker.tuesday_short'),
            this.$i18n.t('drPicker.wednesday_short'),
            this.$i18n.t('drPicker.thursday_short'),
            this.$i18n.t('drPicker.friday_short'),
            this.$i18n.t('drPicker.saturday_short')
          ],
          // dd
          weekdaysMin: [
            this.$i18n.t('drPicker.sunday_short'),
            this.$i18n.t('drPicker.monday_short'),
            this.$i18n.t('drPicker.tuesday_short'),
            this.$i18n.t('drPicker.wednesday_short'),
            this.$i18n.t('drPicker.thursday_short'),
            this.$i18n.t('drPicker.friday_short'),
            this.$i18n.t('drPicker.saturday_short')
          ],
          // first day of week
          firstDayOfWeek: 1,
          // first week contains January 1st.
          firstWeekContainsDate: 1
        },
        // the calendar title of month
        monthFormat: 'MMM',
        // the calendar title of month before year
        monthBeforeYear: true
      }
    },
    /**
     * Используется для установки shortcutsButtons
     */
    setShortcutsButtons() {
      this.shortcutsButtons = this.range ?
        [
          {
            text: this.$i18n.t('drPicker.today'),
            onClick: () => [this.moment().startOf('day').toDate(), this.moment().endOf('day').toDate()]
          },
          {
            text: this.$i18n.t('drPicker.yesterday'),
            onClick: () => [
              this.moment().startOf('day').subtract(1, 'days').toDate(),
              this.moment().endOf('day').subtract(1, 'days').toDate()
            ]
          },
          {
            text: this.$i18n.t('drPicker.week'),
            onClick: () => [this.moment().startOf('week').toDate(), this.moment().endOf('week').toDate()]
          },
          {
            text: this.$i18n.t('drPicker.prev_week'),
            onClick: () => [
              this.moment().startOf('week').subtract(1, 'weeks').toDate(),
              this.moment().endOf('week').subtract(1, 'weeks').toDate()
            ]
          },
          {
            text: this.$i18n.t('drPicker.month'),
            onClick: () => [this.moment().startOf('month').toDate(), this.moment().endOf('month').toDate()]
          },


          {
            text: this.$i18n.t('drPicker.prev_month'),
            onClick: () => {
              const startOfPreviousMonth = this.moment().subtract(1, 'months').startOf('month').toDate()
              const endOfPreviousMonth = this.moment().subtract(1, 'months').endOf('months').toDate()

              // console.log(startOfPreviousMonth, endOfPreviousMonth)

              return [
                startOfPreviousMonth,
                endOfPreviousMonth
              ]
            }
          },
          {
            text: this.$i18n.t('drPicker.last24'),
            onClick: () => [this.moment().subtract(24, 'hours').toDate(), this.moment().toDate()]
          },
          {
            text: this.$i18n.t('drPicker.last48'),
            onClick: () => [this.moment().subtract(48, 'hours').toDate(), this.moment().toDate()]
          },
          {
            text: this.$i18n.t('drPicker.last7'),
            onClick: () => [this.moment().subtract(6, 'days').toDate(), this.moment().toDate()]
          },
          {
            text: this.$i18n.t('drPicker.last30'),
            onClick: () => [this.moment().subtract(29, 'days').toDate(), this.moment().toDate()]
          }
        ] :
        []

    },
    clickToCalendar() {
      // console.log('click!');
      // проверяем задизейблена ли кнопка для выбора кастомных промежутков
      // если нет, значит промежутки по умолчанию меняли, ставим их обратно в значения по умолчанию и из-за этого кнопку снова задизейбливает
      if (!this.isCustomTimeRangeButtonDisabled) {
        this.timeShortcut.amount = '';
        this.timeShortcut.metrics = 'days';
        this.timeShortcut.timeZone = 'last';
      }
    },
    handleCustomTimeRange(emit) {
      let start = this.time[0];
      let end = this.time[1];
      if (this.timeShortcut.timeZone === 'last') {
        start = this.moment().subtract(this.timeShortcut.amount, this.timeShortcut.metrics).toDate();
        end = this.moment().toDate();
      }
      if (this.timeShortcut.timeZone === 'next') {
        start = this.moment().toDate();
        end = this.moment().add(this.timeShortcut.amount, this.timeShortcut.metrics).toDate();
      }

      const range = [start, end];
      emit(range);
    },
    checkIsDateRangeLimitEnabledAndExceeded() {
      if (this.enableMaxDateRangeLimit) {
        const endDate = this.time[1];
        const startDate = this.time[0];
        // console.log(endDate);
        // console.log(startDate);
        // console.log(this.maxDateRangeLimit);
        if (endDate - startDate > this.maxDateRangeLimit) {
          // console.log('more then 3 month');
          return true;
        }
        return false;
      }
      return false;
    }
  }
};
</script>
<style lang="scss">
// vars for datepicker
$default-color: #73879c !default;
$primary-color: #20a8d8 !default;
$input-border-color: rgba(0, 0, 0, 0.15) !default;
$input-color: #555 !default;
$input-hover-border-color: #20a8d8 !default;
@import '~vue2-datepicker/scss/index.scss';
.modal {
  .mx-datepicker {
    width: 100%;
  }
}
.mx-datepicker {
  vertical-align: middle;
  width: 160px;
}
.mx-datepicker.mx-datepicker-range {
  vertical-align: middle;
  width: 310px;
}

.datepicker_height_40 {
  .mx-input {
    height: 40px;
  }
}

.mx-input {
  height: 35px;
  border-radius: 0px;
  padding: 6px 30px;
  padding-left: 10px;
  text-align: center;
  color: #7f8a9b;
  cursor: pointer;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
// .mx-icon-calendar {
//   left: 12px;
//   z-index: 1;
// }
.mx-btn {
  border-radius: 0px;
}

.mx-datepicker-sidebar {
  width: 200px !important;
}
.mx-datepicker-sidebar + .mx-datepicker-content {
  margin-left: 200px !important;
}
.mx-datepicker-popup {
  z-index: 1000000 !important;
}
.mx-table th {
  text-align: center;
}
.mx-w80 {
  max-width: 80px;
}
.datepicker-custom__container {
  height: 29px;
  margin: 30px 10px;
}

.is-danger .mx-input-wrapper input {
  border: 1px solid rgb(255, 56, 96) !important;
}
.datepicker-custom-date-select-button {
  height: 32px !important;
  position: relative;
  top: -1px;
  line-height: 0px;
}
</style>
