import { COUNTRIES_CODES } from './countriesCodes';
import { getMenuNavigationConfig } from './navigation';
/**
 * Конфиг для приложения.
 * Здесь добавляются константы для всего приложения.
 *
 * Ключи для ENV-переменных следует называть единообразно по следующему шаблону: DEFAULT_**NAME**
 * Примеры: DEFAULT_LOCALE, DEFAULT_BUILD и т.д.
 *
 * Ключи для LocalStorage следует называть единообразно по следующему шаблону: STORAGE_KEY_**NAME**
 * Примеры: STORAGE_KEY_LOCALE, STORAGE_KEY_ACCESS и т.д.
 *
 * @typedef {Object} AppConfig
 * @property {string} DEFAULT_LOCALE - Дефолтная локаль билда, полученная из env переменных.
 *
 * @property {string} STORAGE_KEY_LOCALE - Ключ для сохранения текущей локали в LocalStorage.
 */

// *** Дефолтные переменные билда
/**
 * Текущий режим запуска приложения.
 * @constant {"start" | "stop"} APP_MODE
 */
const APP_MODE = process.env.NODE_ENV;
const IS_APP_DEV_MODE = APP_MODE === 'development';

/**
 * Языковая локаль по умолчанию.
 * @constant {string}
 */
const DEFAULT_LOCALE = process.env.VUE_APP_LOCALE;

/**
 * Свернуто ли меню в боковой панели по умолчанию.
 * !info сейчас работает только для нового меню.
 * @constant {string}
 */
const IS_MENU_COLLAPSED = false;

/**
 * URL для доступа к REST API.
 * @constant {string}
 */
const API_URL = process.env.VUE_APP_RESTAPI || '';

/**
 * URL для доступа к REST API раздела Портал.
 * равняется API_URL, но когда-то давно это было не так. Возможно, снова будет нужен отдельный URL
 * @constant {string}
 */
const PORTAL_API_URL = API_URL;

/**
 * URL для доступа к GAPI.
 * Поскольку GAPI это вообще отдельный сервис, адрес которого может теоретически измениться
 * его адрес вынесен в отдельную константу, на случай если когда нибудь адрес сменится
 * В настоящеее время зависит от API_URL следующим образом:
 * Если адрес api (aka backend-golang) https://308.wimark.com, то адрес gapi в данный момент допступен по адресу https://308.wimark.com/api/v2
 * @constant {string}
 */
const GAPI_URL = `${API_URL}/api/v2`;

/**
 * адрес для какого-то медиа-сервиса
 * todo: требуется уточнение, что это за переменная
 *
 * @constant {string}
 */
const WSADDR_URL = process.env.VUE_APP_WSADDR || `wss://${window.location.host}/mediaserver/`;

/**
 * URL для личного кабинета пользователя
 * todo: требуется уточнение
 *
 * @constant {string}
 */

const LK_URL = process.env.VUE_APP_LK_URL || 'http://aa.wimark.com';

/**
 * Брендирование приложения.
 * @constant {("BEELINE-WIMARK" | "QTECH-WIMARK" | "MTS-WIMARK" | "WIMARK" | "BULAT-WIMARK" | "WIFLY-WIMARK")}
 */
const BRANDING = process.env.VUE_APP_CUSTOM_BUILD;

const BRANDS = {
  default: 'WIMARK',
  beeline: 'BEELINE-WIMARK',
  qtech: 'QTECH-WIMARK',
  mts: 'MTS-WIMARK',
  bulat: 'BULAT-WIMARK',
  wifly: 'WIFLY-WIMARK'
};

/**
 * Название или версия текущей сборки приложения.
 *
 * @constant {string}
 */
const BUILD_VERSION = process.env.VUE_APP_BUILD;

/**
 * Дата сборки текущей версии приложения.
 *
 * @constant {string}
 */
const BUILD_DATE = process.env.VUE_APP_BUILD_DATE;

/**
 * Последний коммит, связанный с текущей версией приложения.
 *
 * @constant {string}
 */
const BUILD_GIT_LATEST_COMMIT = process.env.VUE_APP_SERVICE_COMMIT;

/**
 * Название ветки Git, связанной с текущей сборкой приложения.
 *
 * @constant {string}
 */
const BUILD_GIT_BRANCH = process.env.VUE_APP_SERVICE_VERSION;

/**
 * Текущая версия сборки софта.
 *
 * @constant {string}
 */
const BUILD_SOFTWARE_VERSION = process.env.VUE_APP_BUILD_VERSION;

/**
 * Флаг, определяющий активацию или деактивацию раздела портала на сайте.
 *
 * @constant {boolean}
 * @description Если `true`, раздел портала активирован; если `false`, раздел портала деактивирован. Раньше это была window.PORTAL
 */
const CONTENT_PORTAL = Boolean(process.env.VUE_APP_PORTAL);

/**
 * Флаг, определяющий активацию или деактивацию подраздела "Платный интернет" в разделе "Портал" на сайте.
 *
 * @constant {boolean}
 * @description Если `true`, подраздел "Платный интернет" активирован; если `false` - скрыт. Раньше это была window.PAID_INTERNET
 */
const CONTENT_PORTAL_PAID_INTERNET = Boolean(process.env.VUE_APP_PAID_INTERNET);

/**
 * Флаг, определяющий активацию или деактивацию раздела Аналитика на сайте.
 *
 * @constant {boolean}
 * @description Если `true`, раздел Аналитика активирован; если `false` - скрыт. Раньше это была window.ANALYTICS
 */
const CONTENT_ANALYTICS = Boolean(process.env.VUE_APP_ANALYTICS);

/**
 * Флаг, определяющий активацию нового меню вместо старого.
 *
 * @constant {boolean}
 * @description Если `true`, Новое меню активировано; если `false` - старое. Раньше это была window.NEW_MENU_STRUCTURE
 */

const CONTENT_NEW_MENU = Boolean(process.env.VUE_APP_NEW_MENU_STRUCTURE);

/**
 * Флаг, определяющий активацию или деактивацию
 * функций с бесплатной авторизацией по звонку и пейволом на определенных разделах
 *
 * @constant {boolean}
 * @description todoЕсли `true` - активирован; если `false` - скрыт. Раньше это была window.APP_AUTH_FREEMIUM
 */
const CONTENT_AUTH_FREEMIUM = Boolean(process.env.VUE_APP_AUTH_FREEMIUM);

/**
 * Флаг, скрывающий локации.
 *
 * @constant {boolean}
 * @description Если `true`, локации скрыты из интерфейса; если `false` - локации отображаются. Раньше это была window.HIDE_LOCATIONS
 */
const CONTENT_HIDE_LOCATIONS = Boolean(process.env.VUE_APP_HIDE_LOCATIONS);

/**
 * Переключает приложение в режим "Только Портал"
 *
 * @constant {boolean}
 * @description Если `true`, раздел портала активирован, а все остальные принудительно скрыты; если `false`, то остальные разделы не выключаются и приложение работает в обычном режиме. Раньше это была window.PORTAL_ONLY
 */
const CONTENT_PORTAL_ONLY_MODE = Boolean(process.env.VUE_APP_PORTAL_ONLY);

/**
 * Конфигурация режима разлогинивания после бездействия пользователя.
 *
 * @constant {Object}
 *
 * @property {boolean} isEnabled - Определяет, включен ли режим выхода из системы после бездействия пользователя.
 * @property {Object} timeout - Время бездействия пользователя в минутах до выхода из системы.
 * @property {number} timeout.total - Общее время бездействия пользователя в минутах до выхода из системы. Если не указано, используется значение по умолчанию, равное 10 минутам.
 * @property {number} timeout.mainStage - Время, отведенное на таймер mainStage. Доля от timeout.total. По умолчанию - timeout.total - 1 мин.
 * @property {number} timeout.expirationStage -Время, отведенное на таймер expirationStage. 1 мин.
 */

const TOTAL_TIMEOUT = process.env.VUE_APP_LOGOUT_AFTER_USER_INACTIVITY_TIME ? parseInt(process.env.VUE_APP_LOGOUT_AFTER_USER_INACTIVITY_TIME, 0) * 60 * 1000 : 10 * 60 * 1000;

const LOGOUT_AFTER_USER_INACTIVITY_MODE = {
  isEnabled: Boolean(process.env.VUE_APP_LOGOUT_AFTER_USER_INACTIVITY),
  timeout: {
    total: TOTAL_TIMEOUT,
    mainStage: TOTAL_TIMEOUT - (1 * 60) * 1000,
    expirationStage: (1 * 60) * 1000
  }
};

/**
 * Вид ответа, который отдает бэк, когда jwt просрочен или невалиден
 *
 * @type {{MESSAGE: string, STATUS: number}}
 */
const JWT_EXPIRED_RESPONSE_EXAMPLE = {
  STATUS: 401,
  MESSAGE: 'invalid or expired jwt'
};

/**
 * Переключает приложение в режим ""Экспериментальный""
 *
 * @constant {boolean}
 * @description Если `true`, экспериментальные фичи активированы; если `false`, то нет. Раньше это была window.EXPERIMENTAL
 */
const EXPERIMENTAL_MODE = Boolean(process.env.VUE_APP_EXPERIMENTAL);

/**
 * todo: описать значение флага CISCO
 * это легаси-флаг window.CISCO
 * сейчас === EXPERIMENTAL_MODE
 *
 * @constant {boolean}
 * @description Если `true`, включен; если `false`, то нет.
 */
const CISCO_MODE = Boolean(process.env.VUE_APP_CISCO ?? EXPERIMENTAL_MODE);

/**
 * Список запрещенных методов авторазации для портала
 * Нужно для задачи WNE-4007 https://wimarksystems.atlassian.net/browse/WNE-4007
 * Список доступных способов приходит с бэка из ручки https://nmstest.wimark.com/api/portal/admin/authorization
 * Требуется исключить оттуда фейсбук и инстаграм, чтобы не выводить их в UI
 * Также используется для аналогичного отфильтровывания в различных местах UI, в котрых список не приходит с бэка
 * а был захардкожен (например фильтры в разделе Портал - Аналитика и Данные - Найти сессию)
 *
 * @type {string[]}
 */
const PORTAL_PROHIBITED_AUTHORIZATION_TYPES = ['facebook', 'instagram'];

// *** Ключи для LocalStorage
const STORAGE_KEY_LOCALE = 'NMS_LOCALE';
const STORAGE_KEY_THEME = 'wimark-theme';
const STORAGE_KEY_IS_MENU_COLLAPSED = 'wimark-menu-collapsed';
const STORAGE_KEY_ACCESS_TOKEN = 'wimark-access-token';
const STORAGE_KEY_INTERVAL_UPDATE = 'wimark-interval-update';
const STORAGE_KEY_SEARCHBAR_HISTORY = 'wimark-search-history';
// ...

// *** other
const LOCALES = {
  ru: 'ru',
  en: 'en'
};

const THEME_STATES = {
  light: 'light',
  dark: 'dark'
};

const FALLBACK_LOCALE = LOCALES.ru;

export {
  getMenuNavigationConfig,
  APP_MODE,
  IS_APP_DEV_MODE,
  DEFAULT_LOCALE,
  IS_MENU_COLLAPSED,
  API_URL,
  PORTAL_API_URL,
  WSADDR_URL,
  LK_URL,
  BRANDING,
  BUILD_VERSION,
  BUILD_DATE,
  BUILD_GIT_LATEST_COMMIT,
  BUILD_GIT_BRANCH,
  BUILD_SOFTWARE_VERSION,
  CONTENT_PORTAL,
  CONTENT_ANALYTICS,
  CONTENT_PORTAL_PAID_INTERNET,
  CONTENT_AUTH_FREEMIUM,
  CONTENT_PORTAL_ONLY_MODE,
  CONTENT_NEW_MENU,
  CONTENT_HIDE_LOCATIONS,
  EXPERIMENTAL_MODE,
  CISCO_MODE,
  LOGOUT_AFTER_USER_INACTIVITY_MODE,
  STORAGE_KEY_LOCALE,
  STORAGE_KEY_IS_MENU_COLLAPSED,
  STORAGE_KEY_ACCESS_TOKEN,
  STORAGE_KEY_INTERVAL_UPDATE,
  STORAGE_KEY_SEARCHBAR_HISTORY,
  LOCALES,
  FALLBACK_LOCALE,
  BRANDS,
  STORAGE_KEY_THEME,
  THEME_STATES,
  COUNTRIES_CODES,
  PORTAL_PROHIBITED_AUTHORIZATION_TYPES,
  GAPI_URL,
  JWT_EXPIRED_RESPONSE_EXAMPLE
};
