<template>
  <span :class="[!props.row.name && 'muted']">
    {{ props.row.name ? props.formattedRow[props.column.field] : $t('misc.no_name')}}
  </span>
</template>

<script>
/**
 * компонент - ячейка name.
 * компонент локальный: используется только в WlansTable.vue
 * @component
 */

export default {
  name: 'NameCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.with-badge-status {
  gap: 8px;
}

.with-badge-status::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid var(--white);
  border-radius: 50%;
  margin-right: 4px;
}

.with-badge-status.active::before {
  background-color: var(--brand-success);
}

.with-badge-status.inactive::before {
  background-color: var(--gray-light);
}

.muted {
  color: var(--gray-light);
}
</style>
