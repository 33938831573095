<template>
  <WModalWindow
    @close="$emit('close', $event)"
    v-bind="$attrs"
  >
    <div class="content" :class="[viewTypeClass]">
      <div class="header" v-if="withHeader">
        <slot name="header"></slot>
      </div>
      <div class="body" v-if="withBody">
        <slot name="body"></slot>
      </div>

      <div class="footer" v-if="withFooter">
        <slot name="footer"></slot>
      </div>
    </div>
  </WModalWindow>
</template>

<script>
/**
* UI-компонент модального окна WModalContent.
* Используется во всем проекте. Регистрируется глобально (в конкретном компоненте регистрировать не нужно).
* ! Important: Используйте этот компонент в 99% случаев, когда вам нужно сделать модалку.
* ! info: реальные примеры конструкции модалок на основе WModalComponent можно посмотреть src/views/ReactiveLogs/components.
*
* @summary
* Этот компонент отвечает за:
* 1. создание стандартной модалки (99% случаев)
* 2. позволяет задать контент header, body, footer с помощью слотов (все они опциональны)
* 3. позволяет задать тему модалке через пропсы: primary (default), secondary, info, success, warning, danger ( Они проксируются в WModalWindow).
*
* @typedef {Object} WModalContent (все props от WModalWindow. Этот компонент просто проксирует их ему).
* @prop {boolean} isOpen - Флаг, указывающий на открытие/закрытие модального окна.
* @prop {boolean} [primary=true] - Флаг, указывающий на использование стиля "primary".
* @prop {boolean} [secondary=false] - Флаг, указывающий на использование стиля "secondary".
* @prop {boolean} [info=false] - Флаг, указывающий на использование стиля "info".
* @prop {boolean} [success=false] - Флаг, указывающий на использование стиля "success".
* @prop {boolean} [warning=false] - Флаг, указывающий на использование стиля "warning".
* @prop {boolean} [danger=false] - Флаг, указывающий на использование стиля "danger".
*
* @event close - Событие, срабатывающее при закрытии модального окна.
*
* Примеры использования компонента WModalContent:
*
* @example
* Пример 1: Базовое модальное окно с хедером, боди и футером:
* <WModalContent
*   :isOpen="isOpen"
*   @close="handleClose">
*   <div slot="header">Заголовок</div>
*   <div slot="body">Содержимое</div>
*   <div slot="footer">Нижний колонтитул</div>
* </WModalContent>
*
* @example
* Пример 2: Модальное окно только с боди:
* <WModalContent
*   :isOpen="isOpen"
*   @close="handleClose"
* >
*   <div slot="body">Содержимое</div>
* </WModalContent>
*
* @example
* Пример 3: Модальное окно danger:
* <WModalContent danger
*   :isOpen="isOpen"
*   @close="handleClose"
* >
*   <div slot="header">Warning!!!</div>
*   <div slot="body">Содержимое</div>
* </WModalContent>
*
* @example
* Пример 6: Реальное использование. Модальное окно подтверждение удаления сущности.
* <WModalContent danger
*   :isOpen="isOpen"
*   @close="handleClose"
* >
*   <template #header>
*     <h4 class="heading">
*       {{ $t('reactiveLogs.confirmDeletingModalHeader') }}
*     </h4>
*   </template>
*   <template #body>
*     <h6 class="subheading">
*       {{ $t('reactiveLogs.confirmDeletingModal') }}?
*     </h6>
*   </template>
*   <template #footer>
*     <div class="button-section">
*       <WButton secondary outline @click="handleClose">
*         {{ $t('general.cancel') }}
*       </WButton>
*       <WButton danger outline @click="handleConfirm">
*         {{ $t('general.yesDelete') }}
*       </WButton>
*     </div>
*   </template>
* </WModalContent>
*/

export default {
  name: 'WModalContent',
  computed: {
    withHeader() {
      return Boolean(this.$slots?.['header']?.[0]);
    },
    withBody() {
      return Boolean(this.$slots?.['body']?.[0]);
    },
    withFooter() {
      return Boolean(this.$slots?.['footer']?.[0]);
    },
    viewTypeClass() {
      if (this.checkCurrentViewType('secondary')) {
        return 'secondary';
      }

      if (this.checkCurrentViewType('info')) {
        return 'info';
      }

      if (this.checkCurrentViewType('success')) {
        return 'success';
      }

      if (this.checkCurrentViewType('warning')) {
        return 'warning';
      }

      if (this.checkCurrentViewType('danger')) {
        return 'danger';
      }

      return 'primary';
    }
  },
  methods: {
    checkCurrentViewType(type) {
      const attrsKeys = Object.keys(this.$attrs);

      return attrsKeys.includes(type);
    }
  }
};
</script>

<style lang="css" scoped>
  .content > div {
    padding: 16px;
  }

  .header {
    color: var(--modal-header-color);
  }

  /* type classes */
  .primary > .header {
    background-color: var(--modal-primary);
  }

  .primary > div:not(:last-of-type){
    border-bottom: 1px solid var(--modal-primary);
  }

  .secondary > .header {
    background-color: var(--modal-secondary);
  }

  .secondary > div:not(:last-of-type){
    border-bottom: 1px solid var(--modal-secondary);
  }

  .info > .header {
    background-color: var(--modal-info);
  }

  .info > div:not(:last-of-type){
    border-bottom: 1px solid var(--modal-info);
  }

  .success > .header {
    background-color: var(--modal-success);
  }

  .success > div:not(:last-of-type){
    border-bottom: 1px solid var(--modal-success);
  }

  .warning > .header {
    background-color: var(--modal-warning);
  }

  .warning > div:not(:last-of-type){
    border-bottom: 1px solid var(--modal-warning);
  }

  .danger > .header {
    background-color: var(--modal-danger);
  }

  .danger > div:not(:last-of-type){
    border-bottom: 1px solid var(--modal-danger);
  }
</style>
