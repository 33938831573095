/* eslint-disable consistent-return */
import store from '@/store';
import { JWT_EXPIRED_RESPONSE_EXAMPLE } from '@/config';
import { AXIOS_INSTANCE, POST_LOGOUT_URL } from '../config';
import { setIsAuthenticatedStatus } from '../utils';

/**
 * Функция-фетчер для отправки запроса на разлогирование юзера.
 *
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */

const logoutFetcher = async () => {
  try {
    const response = await AXIOS_INSTANCE.post(POST_LOGOUT_URL);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    // для этой ручки получить назад 401 не совсем ошибка, а скорее штатная работа,
    // Такое бывает, когда юзер пытается разлогинится, а у него уже просрочен JWT токен
    // Так что такое мы резолвим чтобы процедура разлогина продолжилась
    if (error.response?.status === JWT_EXPIRED_RESPONSE_EXAMPLE.STATUS &&
      error.response?.data?.message === JWT_EXPIRED_RESPONSE_EXAMPLE.MESSAGE) {
      return Promise.resolve();
    }
    return Promise.reject(error);
  }
};

/**
 * фукнция-обработчик logout.
 * @param {function} callback функция, вызываемая после разлогинивания, обычно функция, выполняющая
 * редирект на страницу логина
 *
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const logout = async (callback) => {
  // ! info:
  // просто вызываем, чтобы на бэке удалился токен юзера.
  // Но вне зависимости от результата выполняем логику ниже, чтобы разлогиниться
  await logoutFetcher();

  setIsAuthenticatedStatus({status: false});

  // todo: это legacy-кусок
  // пока что его некуда деть
  if (store.state.subordinateLogin === true) {
    store.commit('subordinateLoginSetFalse');
    if (localStorage.getItem('bossUserData')) {
      localStorage.removeItem('bossUserData');
    }
  }
  if (callback && typeof callback === 'function') {
    callback();
  }
};

export default logout;
