import { createNamespacedHelpers } from 'vuex';
/**
 * Миксин содержит различне computed используемые для отображение тех или иных настроек WLAN
 * (например в зависимости от сборки )
 * Используется только в разделах с созданием/редактированием WLAN
 *
 * @mixin
 *
 */

const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');

const wlanSettingsVisibilityMixin = {
  computed: {
    ...WLCProductMapGetters(['isWLCProduct']),
    /**
     * Услиовие для отображения правой панели с настройками во вкладке
     * "Дополнительно" - "Управление траффиком данных" в настройках WLAN
     * В настоящее время отображается только если отображается свитчер proxy ARP
     * @returns {boolean}
     */
    showDataTrafficManagementTabRightColumn() {
      return this.showProxyARPSwitcher;
    },
    /**
     * Услиовие для отображения (или же нет) свитчера Proxy ARP.
     * Требование в каких сборках его отображать изменяется уже не в первый раз, поэтому оставлю этот
     * computed, чтобы если что можно было с лёгкостью поменять обратно и отображать например только в WLC
     * В настоящее время отображаем для всех сборок - и WLC и ONE сборки
     * @returns {boolean}
     */
    showProxyARPSwitcher() {
      return true;
    },
    /**
     * Услиовие для отображения (или же нет) селектора интерфейсов в настройках WLAN в зависимости от сборки
     * На вкладке Дополнитльно - Управление трафиком данных, под свитчером Централизованная коммутация
     * В настоящее время не отображаем для WLC сборки
     * @returns {boolean}
     */
    showInterfaceSelectorInCentralSwitchingSettingsDependingOnBuild() {
      // Не показываем в WLC сборке
      if (this.isWLCProduct) {
        return false;
      }
      return true;
    }
  }
};

export default wlanSettingsVisibilityMixin;
